<template id="quote">
  <v-ons-page>
    <custom-toolbar :title="'Get Quote'" :action="toggleMenu"></custom-toolbar>
    <div class="content-box">
      <p class="title">Generate Quote</p>
      <FormGetQuote />
    </div>
  </v-ons-page>
</template>

<style scoped>
.content-box {
  margin-top: 60px;
  text-align: center;
}

.title {
  font-size: 30px;
  padding: 5px;
  color: #4abca5;
}

.bottom-bar {
  background-color: #4abca5;
  border-top-style: solid;
  border-top-color: #837d89;
  border-top-width: 5px;
}
</style>

<script>
import customToolbar from "./PageNav.vue";
import FormGetQuote from "./FormGetQuote.vue";

export default {
  name: "GetQuote",
  template: "quote",
  props: ["toggleMenu"],
  components: {
    customToolbar,
    FormGetQuote,
  },
};
</script>
