<template id="assetdatabase">

 <v-ons-page>
    <v-ons-toolbar>
      <div class="left">
        <router-link :to="`/home`">
          <v-ons-toolbar-button>
            <v-ons-icon
              size="30px"
              style="color: #4abca5"
              icon="fa-arrow-circle-left"
            ></v-ons-icon>
          </v-ons-toolbar-button>
        </router-link>
      </div>
      <div class="center">
        <router-link :to="`/home`">
          <v-ons-toolbar-button tappable>
            <img
              class="header-image-style"
              src="@/assets/Logo.png"
              alt="SFX Logo"
            />
          </v-ons-toolbar-button>
        </router-link>
      </div>
    </v-ons-toolbar>

 <div>


  <div class="content-box">
   <ol class="brand">
        <li v-for="(item, i) in items" :key="i" >
            <router-link :to="`/assethomeclone3`">
        <a  class="make-green"  @click="getNum(i)" :href="`#${item.item}`">{{item.item}}</a>
        </router-link>
        </li>
    </ol>
</div>


  </div>

   </v-ons-page>
</template>

<style scoped>

.header-image-style {
  width: 50px;
  height: 50px;
  object-fit: scale-down;
  padding-bottom: 7px;
}

.table-shadow{
/*box-shadow: rgba(255, 0, 0, 0.35) 0px 5px 15px;*/
border: 2px solid rgba(0, 128, 0, 0.35);
}

.make-green{
  color: #4abca5;
}

.brand{
  color: #837d89;
}

.content-box {
  text-align: center;
  margin: 60px 25px 60px 25px;
}

</style>

<script>

  export default {
    name: 'Assetdatabase',
    template:'assetdatabase',
    data() {
      return {

        assetnum: "",
        items: [],
        first: true,

      }
  },



async mounted() {

 await this.getAssetnums();
},

methods: {

async getAssetnums(){

const data = await fetch("https://www.wolframcloud.com/obj/ccn2/CDN/SFX/table/from1all")
.then((res) => res.json())
this.items = JSON.parse(data).items
console.log(this.items)

},


getNum(i){

fetch("https://www.wolframcloud.com/obj/ccn2/CDN/SFX/vars/last_assetnum?assetnum="+this.items[i].item+'&'+'hash='+this.$root.userhash)
console.log(this.$root.userhash);
console.log(this.items[i].item)
  
}
}



}
</script>