<template id="birtable">
  <div>
    <v-ons-button
      class="bir-link button-link"
      modifier="quiet"
      @click="showModal"
      >See BIR guidlines.</v-ons-button
    >
    <v-ons-modal
      class="modal-color"
      :visible="modalVisible"
      @click="modalVisible = true"
    >
      <div class="box">
        <b-table
          id="repairtable"
          hover
          striped
          bordered
          selectable
          empty-text
          :items="tableData"
          :fields="tableColumns"
          class="mt-3 table-backround make-branded"
        >
<template #table-caption>
         <b-button @click="closeModal" class="button" variant="light"
           >Okay</b-button
                    >
 </template>

        </b-table>

      </div>
    </v-ons-modal>
  </div>
</template>

<style scoped>
.modal-color {
  background-color: rgba(245, 245, 245, 0.85);
}

.bir-link {
  font-style: italic;
  font-size: 15px;
  color: #837d89;
}

.button-link {
  background-color: transparent;
}

.table-backround {
  background-color: rgba(131, 125, 137, 0.15);
  box-shadow: rgba(74, 188, 165, 0.95) 0px 5px 15px;
}

.button{
  background-color: #4abca5;
  color: white;
}

.box {
  text-align: center;
  margin: 60px 25px 25px 25px;


}

.make-branded {
  color: #4abca5;
}
</style>

<script>
export default {
  name: "TableBIR",
  template: "birtable",
  data() {
    return {
      /*

<b-card class="mt-3" header="Form Data Result">
    <pre class="m-0">{{ tableData }}</pre>
   </b-card>

        */

      modalVisible: false,
      timeoutID: 0,

      tableData: [
        {
          Location: "Lead Apron: Over Critical Organ",
          MaximumArea: "15mm^2 (4.3mm diameter circle)",
          MaximumLength: "20mm",
        },
        {
          Location: "Lead Apron: Not Over Critical Organ",
          MaximumArea: "670mm^2 (29mm diameter circle)",
          MaximumLength: "50mm",
        },

        {
          Location: "Thyroid Collar: Anywhere",
          MaximumArea: "11mm^2 (3.8mm diameter circle)",
          MaximumLength: "20mm",
        },
      ],
      tableColumns: [
        { key: "Location", label: "Location of Defect", sortable: false },
        {
          key: "MaximumArea",
          label: "Maximum Tolerable Defect Area",
          sortable: false,
        },
        {
          key: "MaximumLength",
          label: "Maximum Tolerable Defect Tear Length",
          sortable: false,
        },
      ],
    };
  },
  methods: {
    showModal() {
      this.modalVisible = true;
    },

    closeModal() {
      this.modalVisible = false;
      clearTimeout(this.timeoutID);
      this.timeoutID = setTimeout(() => (this.modalVisible = false), 100);
    },
    error: function (err) {
      console.log(err);
    },
  },
};
</script>
