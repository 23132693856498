import 'mutationobserver-shim'
import Vue from 'vue'
import App from './App.vue'
import VueQrcodeReader from 'vue-qrcode-reader'
import router from "./router";
import WebCam from 'vue-web-cam';
import store from './store';
import axios from 'axios'
import VueAxios from 'vue-axios'
import './plugins/bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'onsenui/css/onsenui.css';
import 'onsenui/css/onsen-css-components.css';
import VueOnsen from 'vue-onsenui'; // This imports 'onsenui', so no need to import it separately
import VueQrcode from '@chenfengyuan/vue-qrcode';

Vue.component(VueQrcode.name, VueQrcode);
Vue.use(VueQrcodeReader)
Vue.use(WebCam);
Vue.use(VueAxios, axios)
Vue.use(VueOnsen); // VueOnsen set here as plugin to VUE. Done automatically if a call to window.Vue exists in the startup code.
Vue.config.productionTip = false

axios.defaults.withCredentials = true
axios.interceptors.response.use(undefined, function (error) {
  if (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {

      originalRequest._retry = true;
      store.dispatch('LogOut');
      return router.push('/');
    }
  }
})


Vue.prototype.$token = '';

new Vue({
  data:{$userhash:'undefined',$usr:'undefined',$grp:'undefined',$pass:'undefined', $acc:'undefined', $mail:'undefined'},
  store,
  components: {App},
  router,
  el: '#app',
  template: '<App/>',
  render: h => h(App),
}).$mount('#app')
