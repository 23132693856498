<template>
  <v-ons-page>
    <v-ons-toolbar class="top-bar">
      <div class="left">
        <router-link :to="`/home`">
          <v-ons-toolbar-button>
            <v-ons-icon
              size="30px"
              style="color: white"
              icon="fa-arrow-circle-left"
            ></v-ons-icon>
          </v-ons-toolbar-button>
        </router-link>
      </div>
      <div class="center">
        <router-link :to="`/home`">
          <v-ons-toolbar-button tappable>
            <img
              class="header-image-style"
              src="@/assets/Logo.png"
              alt="SFX Logo"
            />
          </v-ons-toolbar-button>
        </router-link>
      </div>
    </v-ons-toolbar>

    <div class="fullscreen">
      <!-- <qrcode-stream :key="_uid" :track="selected.value" @init="logErrors" /> -->
      <qrcode-stream
        @init="onInit"
        @decode="onDecode"
        :paused="paused"
        :track="paintOutline"
      >
        <div v-if="decodedContent !== null" class="decoded-content qr-text">
          {{ decodedContent }}
        </div>
      </qrcode-stream>
      <div class="error">
        {{ errorMessage }}
      </div>
    </div>

    <v-ons-bottom-toolbar class="bottom-bar">
    </v-ons-bottom-toolbar>
  </v-ons-page>
</template>

<style scoped>
.header-image-style {
  width: 50px;
  height: 50px;
  object-fit: scale-down;
  padding-bottom: 7px;
}

.qr-text {
  font-size: 30px;
  padding: 40px;
  color: #4abca5;
  text-align: center;
}

.top-bar {
  background-color: rgba(255, 255, 255, 0.35);
  height: 50px;
}

.bottom-bar {
  background-color: rgba(255, 255, 255, 0.35);
  height: 50px;
}

.flex {
  display: flex;
  justify-content: space-between;
  align-content: center;
}

.fullscreen {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: black;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
}
</style>

<script>

//background-color: black;

import QrcodeStream from "./QrcodeStream.vue";

export default {
  components: { QrcodeStream },

  data() {
    return {
      paused: false,
      decodedContent: null,
      errorMessage: "",
    };
  },

  methods: {

   onDecode(decodedContent) {
   //window.location.href = url
   this.decodedContent = decodedContent
  this.$router.push({ name: 'AssetHome', params: { id: this.decodedContent }})
},

    onInit(promise) {
      promise
        .then(() => {
          console.log("Successfully initilized! Ready for scanning now!");
        })
        .catch((error) => {
          if (error.name === "NotAllowedError") {
            this.errorMessage = "Hey! I need access to your camera";
          } else if (error.name === "NotFoundError") {
            this.errorMessage = "Do you even have a camera on your device?";
          } else if (error.name === "NotSupportedError") {
            this.errorMessage =
              "Seems like this page is served in non-secure context (HTTPS, localhost or file://)";
          } else if (error.name === "NotReadableError") {
            this.errorMessage =
              "Couldn't access your camera. Is it already in use?";
          } else if (error.name === "OverconstrainedError") {
            this.errorMessage =
              "Constraints don't match any installed camera. Did you asked for the front camera although there is none?";
          } else {
            this.errorMessage = "UNKNOWN ERROR: " + error.message;
          }
        });
    },

    paintOutline(detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const [firstPoint, ...otherPoints] = detectedCode.cornerPoints;

        ctx.strokeStyle = "#4abca5";

        ctx.beginPath();
        ctx.moveTo(firstPoint.x, firstPoint.y);
        for (const { x, y } of otherPoints) {
          ctx.lineTo(x, y);
        }
        ctx.lineTo(firstPoint.x, firstPoint.y);
        ctx.closePath();
        ctx.stroke();
      }
    },

    logErrors(promise) {
      promise.catch(console.error);
    },
  },
};
</script>