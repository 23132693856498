<template id="quoteform">
  <div>
      <b-form @submit="onSubmit" v-if="show" class="form-shadow-box content-box">
      
        <b-form-group
        id="hospital-input-group"
        label="Hospital:"
        label-for="hospital"
      >
        <b-form-input
          placeholder="Select Or Type"
          v-model="form.hospital0"
          list="hospital-list"
          id="hospital"
          required
          class="form-shadow"
        ></b-form-input>
        <b-form-datalist
          id="hospital-list"
          :options="hospital"
        ></b-form-datalist>
      </b-form-group>

      <b-form-group
        id="location-input-group"
        label="Location:"
        label-for="location"
      >
        <b-form-input
          placeholder="Select Or Type"
          v-model="form.location0"
          list="location-list"
          id="location"
          required
          class="form-shadow"
        ></b-form-input>
        <b-form-datalist
          id="location-list"
          :options="location"
        ></b-form-datalist>
      </b-form-group>

     <b-form-group
        id="assettype-input-group"
        label="Asset Type:"
        label-for="assettype"
      >
        <b-form-input
          placeholder="Select Or Type"
          v-model="form.assettype0"
          list="assettype-list"
          id="assettype"
          required
          class="form-shadow"
        ></b-form-input>
        <b-form-datalist
          id="assettype-list"
          :options="assettype"
        ></b-form-datalist>
      </b-form-group>

      <div>
        <div v-if="form.assettype0 === 'Thyroid Collar'">
          <transition name="fade"></transition>
        </div>
        <div v-else-if="form.assettype0 === 'Glasses'">
          <transition name="fade"></transition>
        </div>
        <div v-else-if="form.assettype0 === 'Cap'">
          <transition name="fade"></transition>
        </div>
        <div v-else-if="form.assettype0 === 'Drapes'">
          <transition name="fade"></transition>
        </div>

        <div v-else>
          <transition name="fade">
            <b-form-group
              id="subtype-input-group"
              label="Subtype:"
              label-for="subtype"
            >
              <b-form-input
                placeholder="Select Or Type"
                v-model="form.subtype0"
                list="subtype-list"
                id="subtype"
                required
                class="form-shadow"
              ></b-form-input>
              <b-form-datalist
                id="subtype-list"
                :options="subtype"
              ></b-form-datalist>
            </b-form-group>
          </transition>
        </div>
      </div>

      <div>
        <div v-if="form.assettype0 === 'Thyroid Collar'">
          <transition name="fade"></transition>
        </div>
        <div v-else-if="form.assettype0 === 'Glasses'">
          <transition name="fade"></transition>
        </div>
        <div v-else-if="form.assettype0 === 'Cap'">
          <transition name="fade"></transition>
        </div>
        <div v-else-if="form.assettype0 === 'Drapes'">
          <transition name="fade"></transition>
        </div>

        <div v-else>
          <transition name="fade">
            <b-form-group
              id="overlap-input-group"
              label="Front Overlap:"
              label-for="overlap"
            >
              <b-form-input
                placeholder="Select Or Type"
                list="overlap-list"
                v-model="form.overlap0"
                id="overlap"
                required
                class="form-shadow"
              ></b-form-input>
              <b-form-datalist
                id="overlap-list"
                :options="overlap"
              ></b-form-datalist>
            </b-form-group>
          </transition>
        </div>
      </div>


      <b-form-group
        id="department-input-group"
        label="Department:"
        label-for="department-input"
      >
        <b-form-input
          placeholder="Select Or Type"
          v-model="form.department0"
          list="department-list"
          id="department-input"
          required
          class="form-shadow"
        ></b-form-input>
        <b-form-datalist
          id="department-list"
          :options="department"
        ></b-form-datalist>
      </b-form-group>

      <div>
        <div v-if="form.assettype0 === 'Glasses'">
          <transition name="fade"></transition>
        </div>

        <div v-else>
          <transition name="fade">
            <b-form-group
              id="barrier-input-group"
              label="Barrier Material:"
              label-for="barrier-input"
            >
              <b-form-input
                placeholder="Select Or Type"
                v-model="form.barrier0"
                list="barrier-list"
                id="barrier-input"
                required
                class="form-shadow"
              ></b-form-input>
              <b-form-datalist
                id="barrier-list"
                :options="barrier"
              ></b-form-datalist>
            </b-form-group>
          </transition>
        </div>
      </div>

      <div>
        <div v-if="form.assettype0 === 'Apron'">
          <transition name="fade"></transition>
        </div>

        <div v-else>
          <transition name="fade">
            <b-form-group
              id="mmLE-input-group"
              label="mmLE:"
              label-for="mmLE-input"
            >
              <b-form-input
                placeholder="Select Or Type"
                v-model="form.mmLE0"
                list="mmLE-list"
                id="mmLE-input"
                required
                class="form-shadow"
              ></b-form-input>
              <b-form-datalist id="mmLE-list" :options="mmLE"></b-form-datalist>
            </b-form-group>
          </transition>
        </div>
      </div>

      <div>
        <div v-if="form.assettype0 === 'Apron'">
          <transition name="fade">
            <b-form-group
              id="front-input-group"
              label="Front mmLE:"
              label-for="front-input"
            >
              <b-form-input
                placeholder="Select Or Type"
                v-model="form.front0"
                list="front-list"
                id="front-input"
                required
                class="form-shadow"
              ></b-form-input>
              <b-form-datalist
                id="front-list"
                :options="front"
              ></b-form-datalist>
            </b-form-group>
          </transition>
        </div>

        <div v-else>
          <transition name="fade"></transition>
        </div>
      </div>

      <div>
        <div v-if="form.assettype0 === 'Apron'">
          <transition name="fade">
            <b-form-group
              id="back-input-group"
              label="Back mmLE:"
              label-for="back-input"
            >
              <b-form-input
                placeholder="Select Or Type"
                v-model="form.back0"
                list="back-list"
                id="back-input"
                required
                class="form-shadow"
              ></b-form-input>
              <b-form-datalist id="back-list" :options="back"></b-form-datalist>
            </b-form-group>
          </transition>
        </div>

        <div v-else>
          <transition name="fade"></transition>
        </div>
      </div>

      <b-form-group
        id="pattern-input-group"
        label="Outer Colour/Pattern:"
        label-for="pattern-input"
      >
        <b-form-input
          placeholder="Select Or Type"
          v-model="form.pattern0"
          list="pattern-list"
          id="pattern-input"
          required
          class="form-shadow"
        ></b-form-input>
        <b-form-datalist id="pattern-list" :options="pattern"></b-form-datalist>
      </b-form-group>


      <div class="flex-container">
        <b-button
          class="button"
          variant="light"
          type="submit"
          @click="sendMail()"
          >Get Quote</b-button
        >

        <SFXBrochure />
      </div>
    </b-form>
  </div>
</template>


<style scoped>
.flex-container {
  display: flex;
  justify-content: space-between;
}

.content-box {
  margin: 25px;
  text-align: left;
}

.button {
  background-color: #4abca5;
  color: white;
  margin-right: 20px;
}

.form-backround {
  background-color: rgba(131, 125, 137, 0.15);
}
t .form-shadow {
  box-shadow: rgba(74, 188, 165, 0.35) 0px 5px 15px;
}

.form-shadow-box {
  box-shadow: rgba(74, 188, 165, 0.35) 0px 5px 15px;
  padding: 10px;
  border-radius: 25px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to  /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>

<script>
import SFXBrochure from "./SFXBrochure.vue";

export default {
  name: "quoteForm",
  template: "quoteform",

  components: {
    SFXBrochure,
  },

  data() {
    return {
      /*

        <b-card class="mt-3" header="Form Data Result">
      <pre class="m-0">{{ form }}</pre>
    </b-card>

        */

      form: {
        hospital0: '',
        location0: '',
        assettype0: '',
        subtype0: '',
        overlap0: '',
        department0: '',
        barrier0: '',
        mmLE0: '',
        front0: '',
        back0: '',
        pattern0: ''
      },
      hospital: ["The Mater", ""],
      location: ['ROI/NI', 'Scotland', 'North Wales','Midlands','North West England','North East England','London','South Wales','South East England','South West England'],
      assettype: ["Apron", "Thyroid Collar", "Glasses", "Cap", "Drapes"],
      subtype: ["One-Piece", "Two-Piece"],
      overlap: [
        "Full Front Overlap",
        "Partial Front Overlap",
        "No Front Overlap",
      ],
      department: [
        "Radiology",
        "Cath Lab",
        "Emergency",
        "Diagnostic Imaging department",
        "Dental",
        "Pharmacy"
      ],
      barrier: ["Low Lead Bilayer", "Lead Free Bilayer", "Composite", "Unsure"],
      mmLE: ["0.25", "0.35", "0.50", "Unsure"],
      front: ["0.175", "0.25", "0.35", "0.50", "Unsure"],
      back: ["0.25", "0.35", "0.50", "Unsure"],
      pattern: ["Blue", "Charcoal", "Navy", "Black"],

      show: true,
    };
  },
  methods: {

       onSubmit(event) {

     if(event.preventDefault()){ 
       event.preventDefault()      
     } 
     else{
     alert('Thank you for choosing SFX! Your sales rep will reach out shorly with quote details.'),
         this.form.hospital0 = '',
         this.form.location0 = '',  
         this.form.assettype0= '',
         this.form.subtype0= '',
         this.form.overlap0= '',
         this.form.department0= '',
         this.form.barrier0= '',
         this.form.mmLE0= '',
         this.form.front0= '',
         this.form.back0= '',
         this.form.pattern0= ''

     }
     
      
        console.log("Validation was executed")
      },

    sendMail(){
 
 //if(this.form.location0 == 'ROI/NI'){ this.email = 'imran@compdatanotes.com'}
 //if(this.form.location0 == 'Scotland'){ this.email = 'imran@compclassnotes.com'}

      if(this.form.location0 == 'ROI/NI'){ this.email = 'christine@sfxray.com'}

      if(this.form.location0 == 'Scotland'){ this.email = 'sorosh@sfxray.com'}
      if(this.form.location0 == 'North Wales'){ this.email = 'sorosh@sfxray.com'}
      if(this.form.location0 == 'Midlands'){ this.email = 'sorosh@sfxray.com'}
      if(this.form.location0 == 'North West England'){ this.email = 'sorosh@sfxray.com'}
      if(this.form.location0 == 'North East England'){ this.email = 'sorosh@sfxray.com'}

      if(this.form.location0 == 'London'){ this.email = 'liam@sfxray.com'}
      if(this.form.location0 == 'South Wales'){ this.email = 'liam@sfxray.com'}
      if(this.form.location0 == 'South East England'){ this.email = 'liam@sfxray.com'}
      if(this.form.location0 == 'South West England'){ this.email = 'liam@sfxray.com'}


console.log(this.form.location0, this.email)

fetch("https://www.wolframcloud.com/obj/ccn2/CCN/messaging/email/sendmail?to="+this.email+"&subject=Quote%20Request&cc="+this.email+"&body=Order%20Details:%20"+this.form.hospital0+", "+this.form.location0+", "+this.form.assettype0+", "+this.form.subtype0+", "+this.form.overlap0+", "+this.form.department0+", "+this.form.barrier0+", "+this.form.mmLE0+", "+this.form.front0+", "+this.form.back0+", "+this.form.pattern0+".")
this.onSubmit()
    }

  },
};
</script>
