  <template id="screen">
  <v-ons-page>
    <v-ons-toolbar>
      <div class="left">
         <router-link  :to="{ name: 'AssetHome', params: { id: assetnum }}">
          <v-ons-toolbar-button>
            <v-ons-icon
              size="30px"
              style="color: #4abca5"
              icon="fa-arrow-circle-left"
            ></v-ons-icon>
          </v-ons-toolbar-button>
        </router-link>
      </div>
      <div class="center">
        <router-link :to="`/home`">
          <v-ons-toolbar-button tappable>
            <img
              class="header-image-style"
              src="@/assets/Logo.png"
              alt="SFX Logo"
            />
          </v-ons-toolbar-button>
        </router-link>
      </div>
      <div class="right">
        <v-ons-toolbar-button
         id="formprint"
        tappable
        @click="Print()"
        >
          <v-ons-icon
            size="30px"
            style="color: #4abca5"
            icon="fa-file-text"
          ></v-ons-icon>
        </v-ons-toolbar-button>
      </div>
    </v-ons-toolbar>

    <div class="content-box">
      <div>
       <div>
          <img
          v-if="assettype == null"
            class="image-box"
            src="@/assets/Logo.png"
            alt="SFX PPE"
          />
          <img
          v-else
            class="image-box"
            :src="assettype"
            alt="SFX PPE"
          />
          <figcaption class="caption">Asset ID: {{ assetnum }}</figcaption>
        </div>
      </div>
    </div>
    <div>
    <b-form
      v-if="show"
      class="form-shadow-box content-box-2"
      @submit="onSubmit"
    >
      <b-form-group id="date-input-group" label="Date:" label-for="date-input">
        <b-form-input
          id="date-input"
          type="date"
          v-model="form.date0"
          :disabled="disabled == 1"
          required
          class="form-shadow"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        id="visual-input-group"
        label="Visual Inspection:"
        label-for="visual-input"
      >
        <b-form-select
          id="visual-input"
          v-model="form.visual0"
          :disabled="disabled == 1"
          :options="visual"
          required
          class="form-shadow"
        ></b-form-select>
      </b-form-group>

      <b-form-group
        id="fluoro-input-group"
        label="Fluoro Inspection:"
        label-for="fluoro-input"
      >
        <b-form-select
          id="fluoro-input"
          v-model="form.fluoro0"
          :disabled="disabled == 1"
          :options="fluoro"
          required
          class="form-shadow"
        ></b-form-select>
      </b-form-group>

      <b-form-group
        id="disinfection-input-group"
        label="Disinfection:"
        label-for="disinfection"
      >
        <b-form-input
          placeholder="Select Or Type"
          v-model="form.disinfection0"
          list="disinfection-list"
          id="disinfection"
          :disabled="disabled == 1"
          required
          class="form-shadow"
        ></b-form-input>
        <b-form-datalist
          id="disinfection-list"
          :options="disinfection"
        ></b-form-datalist>
      </b-form-group>

      <b-form-group
        id="trafficlight-input-group"
        label="Traffic Light Colour:"
        label-for="trafficlight-input"
      >
        <b-form-select
          id="trafficlight-input"
          v-model="form.trafficlight0"
          :options="trafficlight"
          :disabled="disabled == 1"
          required
          class="form-shadow"
        ></b-form-select>
      </b-form-group>

      <b-form-group
        id="nextinspection-input-group"
        label="Next Inspection:"
        label-for="nextinspection-input"
      >
        <b-form-select
          id="nextinspection-input"
          v-model="form.nextinspection0"
          :options="nextinspection"
          :disabled="disabled == 1"
          required
          class="form-shadow"
        ></b-form-select>
      </b-form-group>
      <div class="flex-container">
        
        <b-button
          :disabled="disabled == 1"
          @click="[onSave(),disabled = (disabled + 1) % 2]"
          class="button"
          variant="light"
          type="submit"
          >Save</b-button
        >
       
        <b-button
          class="button"
          variant="light"
          :disabled="disabled == 0"
          @click="disabled = (disabled + 1) % 2"
          >Edit</b-button
        >
      </div>
    </b-form>
  </div>
    <v-ons-bottom-toolbar class="bottom-bar"></v-ons-bottom-toolbar>
  </v-ons-page>
</template>

<style scoped>
.header-image-style {
  width: 50px;
  height: 50px;
  object-fit: scale-down;
  padding-bottom: 7px;
}

.image-box {
  width: 250px;
  height: 250px;
  margin: 0px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: scale-down;
}

.content-box {
  margin: 60px 25px 0px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}


.caption {
  font-size: 20px;
  padding: 5px;
  color: #837d89;
  text-align: center;
}

.bottom-bar {
  background-color: #4abca5;
  border-top-style: solid;
  border-top-color: Grey;
  border-top-width: 5px;
}

.content-box-2 {
  margin: 25px;
  text-align: left;
}

.flex-container {
  display: flex;
  justify-content: space-between;
}

.form-shadow {
  box-shadow: rgba(74, 188, 165, 0.35) 0px 5px 15px;
}

.form-shadow-box {
  box-shadow: rgba(74, 188, 165, 0.35) 0px 5px 15px;
  padding: 10px;
  border-radius: 25px;
}

.button {
  background-color: #4abca5;
  color: white;
  margin-right: 20px;
}
</style>

<script>

export default {
  name: "ScreenAsset",
  template: "screen",
  components: {
   
  },
  data() {
    return {

    assetnum: "",
    assettype: null,
    disabled: 0,
    

      form: {
        date0: '',
        visual0: '',
        fluoro0: '',
        disinfection0: '',
        trafficlight0: '',
        nextinspection0: '',
      },
      visual: [{ text: "Select One", value: '' }, "Complete", "Incomplete"],
      fluoro: [{ text: "Select One", value: '' }, "Complete", "Incomplete"],
      disinfection: ["Chlorine", "Alcohol", "SteriTouch", "None"],
      trafficlight: [
        { text: "Select One", value: '' },
        "Red",
        "Amber",
        "Green",
      ],
      nextinspection: [
        { text: "Select One", value: '' },
        "0 Months",
        "6 Months",
        "12 Months",
      ],
      show: true,
    };
  },


created(){
this.getNum();

},


 async mounted() {
  
  
  await this.getImage();
  await this.getData();

},


methods: {

   
getNum(){

this.assetnum = this.$route.params.id
console.log("hash",this.$root.userhash, " id0 = ",this.$route.params.id," id1:",this.assetnum);
  
},

 async getData() {
    const data = await fetch("https://www.wolframcloud.com/obj/ccn2/CDN/SFX/forms/getform3?assetnum="+this.assetnum).then((res) => res.json())

         this.form.date0 = JSON.parse(data).date0,
         this.form.visual0 = JSON.parse(data).visual0,
         this.form.fluoro0 = JSON.parse(data).fluoro0,
         this.form.disinfection0 = JSON.parse(data).disinfection0,
         this.form.trafficlight0 = JSON.parse(data).trafficlight0,
         this.form.nextinspection0 = JSON.parse(data).nextinspection0
         console.log("num on getData:",this.assetnum);
  },



   async getImage() {
    const data = await fetch("https://www.wolframcloud.com/obj/ccn2/CDN/SFX/forms/getform1?assetnum="+this.assetnum).then((res) => res.json())

    this.assettype = JSON.parse(data).assettype0
    if(this.assettype == "Apron"){ this.assettype = require('@/assets/Apron.png')}
    if(this.assettype == "Thyroid Collar"){ this.assettype = require('@/assets/Thyroidcollar.png')}
    if(this.assettype == "Glasses"){ this.assettype = require('@/assets/Glasses.png')}
    if(this.assettype == "Cap"){ this.assettype = require('@/assets/Cap.png')}
    if(this.assettype == "Drapes"){ this.assettype = require('@/assets/Drapes.png')}
    if(this.assettype == "null"){ this.assettype = require('@/assets/Logo.png')}
    console.log("num on getImage: ", this.assettype)  
  },

   onSubmit(event) {

const paramid = this.assetnum

     if(event.preventDefault()){ 
       event.preventDefault()      
     } 
     else{
       this.$router.push({ name: 'AssetHome', params: { id: paramid  } })
     }
      
        console.log("Validation was executed")
      },

    onSave(){
fetch("https://www.wolframcloud.com/obj/ccn2/CDN/SFX/forms/saveform3?assetnum="+this.assetnum+"&"+"data="+this.form.date0+","+this.form.visual0+","+this.form.fluoro0+","+this.form.disinfection0+","+this.form.trafficlight0+","+this.form.nextinspection0+"&"+"user="+this.$root.usr+"&"+"group="+this.$root.grp)
  this.onSubmit()
  console.log("num on onSave: ",this.assetnum);
  },

Print () {
  document.getElementById("formprint")
  window.print();
    }

}


};
</script>
