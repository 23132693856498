<template id="camcap">
  <div>
    <v-ons-toolbar class="top-bar">
      <div class="left">
        <router-link :to="{ name: 'ReportDefect', params: { id: param }}">
          <v-ons-toolbar-button>
            <v-ons-icon
              size="30px"
              style="color: white"
              icon="fa-arrow-circle-left"
            ></v-ons-icon>
          </v-ons-toolbar-button>
        </router-link>
      </div>
      <div class="center">
        <router-link :to="`/home`">
          <v-ons-toolbar-button tappable>
            <img
              class="header-image-style"
              src="@/assets/Logo.png"
              alt="SFX Logo"
            />
          </v-ons-toolbar-button>
        </router-link>
      </div>

      <div class="right">
        <b-form-select class="form-shadow" v-model="camera">
          <option>Select Device</option>
          <option
            v-for="device in devices"
            :key="device.deviceId"
            :value="device.deviceId"
          >
            {{ device.label }}
          </option>
        </b-form-select>
      </div>
    </v-ons-toolbar>

    <div>
      <vue-web-cam
        ref="webcam"
        :device-id="deviceId"
        class="camera-full"
        @started="onStarted"
        @stopped="onStopped"
        @error="onError"
        @cameras="onCameras"
        @camera-change="onCameraChange"
      />
    </div>

    <v-ons-bottom-toolbar class="bottom-bar flex">
      <v-ons-toolbar-button
        class="photo-button"
        @click="[onCapture(), showModal(), onStop()]"
      >
        <div class="circle"></div>
        <div class="ring"></div>
      </v-ons-toolbar-button>
    </v-ons-bottom-toolbar>

    <v-ons-modal
      class="modal-color"
      :visible="modalVisible"
      @click="modalVisible = true"
    >
      <figure class="figure content-box border modal-bar">
        <img :src="img" class="img-responsive obj-fit">

      <div><p class="text-style">{{ param }}</p></div>

        <div class="flex-container">
         <v-ons-button
            class="button"
            @click="[closeModal(), onStart()]"
            >Save</v-ons-button>
          

          <v-ons-button class="button" @click="[closeModal(), onStart()]"
            >Retake
          </v-ons-button>
        </div>
      </figure>
    </v-ons-modal>
  </div>
</template>

<style scoped>
.header-image-style {
  width: 50px;
  height: 50px;
  object-fit: scale-down;
  padding-bottom: 7px;
}

.obj-fit {
  height: auto;
  width: 100%;
  /* even more control with max-width */
  max-width: 720px;
}

.form-shadow {
  box-shadow: rgba(74, 188, 165, 0.35) 0px 5px 15px;
  border-style: transparent;
}

.top-bar {
  background-color: rgba(255, 255, 255, 0.35);
  height: 50px;
}

.bottom-bar {
  background-color: rgba(255, 255, 255, 0.35);
  height: 105px;
}

.flex {
  display: flex;
  justify-content: center;
  align-content: center;
}

.flex-container {
  display: flex;
  justify-content: space-between;
}

.text-style {
  text-align: center;
  color: #4abca5;
  margin-top: 10px;
  font-size: 25px;
}

.camera-full {
  width: 100vw;
  height: 100vh;
  position: fixed;
  object-fit: cover;
  object-position: bottom;
  background-color: black;
}

.button {
  background-color: #4abca5;
  color: white;
  margin: 10px;
}

.capture {
  background-color: transparent;
  color: white;
  margin: 10px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to  /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.modal-color {
  background-color: rgba(131, 125, 137, 0.8);
}

.modal-bar {
  background-color: rgba(255, 255, 255, 0.35);
  box-shadow: rgba(74, 188, 165, 0.5) 0px 5px 15px;
}

.content-box {
  margin: 25px;
}

.photo-button {
  width: 100px;
  height: 100px;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -50px;
  position: absolute;
}
.circle {
  position: absolute;
  top: 12%;
  left: 12%;
  bottom: 12%;
  right: 12%;
  border-radius: 100%;
  background-color: #ffffff;
  opacity: 0;
}
.ring {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  border-radius: 100%;
  border: 0.5em solid #ffffff;
  opacity: 0.8;
}
.photo-button .circle,
.photo-button .ring {
  transition: all 0.25s;
}
.photo-button:hover .circle {
  opacity: 1;
}
.photo-button:active .ring {
  opacity: 1;
}
.photo-button:active .circle {
  opacity: 0.5;
}
</style>

<script>

// background-color: black;
import WebCam from "./webcam.vue";

export default {
  name: "CameraCapture",
  template: "camcap",

  props: [],

  components: {
    "vue-web-cam": WebCam,
  },

  data() {
    return {
      param:"",
      img: null,
      link: "#",
      camera: null,
      deviceId: null,
      devices: [],
      showimg: false,
      modalVisible: false,
      timeoutID: 0,
    };
  },
  computed: {
    device: function () {
      return this.devices.find((n) => n.deviceId === this.deviceId);
    },
  },

  watch: {
    camera: function (id) {
      this.deviceId = id;
    },
    devices: function () {
      // Once we have a list select the first one
      const [first, ...tail] = this.devices;
      if (first) {
        this.camera = first.deviceId;
        this.deviceId = first.deviceId;
      }
    },
  },

   async mounted() {
 await this.AssetNum();
},



  methods: {


 AssetNum() {
        this.param = this.$route.params.id;
        console.log("route = ",this.param)
      
      },

    onCapture() {
      this.img = this.$refs.webcam.capture();

  fetch("https://www.wolframcloud.com/obj/ccn2/CDN/SFX/image/largebase64",  {
    method: "POST",
    body: this.param+",,,"+this.img,
    mode: 'cors'
  })
    .then(response => response.json())
    .then(data => (this.postId = data.id));
    console.log(this.img.length)

    },

/*

  saveImage(){
fetch(`https://www.wolframcloud.com/obj/ccn2/CCN/messaging/email/sendmail?to=dara@compclassnotes.com&subject=Defect%20Image%20Of%20Asset%20${this.param}%20In%20${this.$root.grp}&cc=imran@compdatanotes.com&body=`+this.img)
console.log(this.img)
},

*/



    onStarted(stream) {
      console.log("On Started Event", stream);
    },
    onStopped(stream) {
      console.log("On Stopped Event", stream);
    },
    onStop() {
      this.$refs.webcam.stop();
    },
    onStart() {
      this.$refs.webcam.start();
    },
    onError(error) {
      console.log("On Error Event", error);
    },
    onCameras(cameras) {
      this.devices = cameras;
      console.log("On Cameras Event", cameras);
    },
    onCameraChange(deviceId) {
      this.deviceId = deviceId;
      this.camera = deviceId;
      console.log("On Camera Change Event", deviceId);
    },

    showModal() {
      this.modalVisible = true;
      clearTimeout(this.timeoutID);
      this.timeoutID = setTimeout(() => (this.modalVisible = true), 10000);
    },
    closeModal() {
      this.modalVisible = false;
      clearTimeout(this.timeoutID);
      this.timeoutID = setTimeout(() => (this.modalVisible = false), 100);
    },
  },
};
</script>
