<template id="manualqr">
  <v-ons-page>
    <v-ons-toolbar>
      <div class="left">
        <router-link :to="`/home`">
          <v-ons-toolbar-button>
            <v-ons-icon
              size="30px"
              style="color: #4abca5"
              icon="fa-arrow-circle-left"
            ></v-ons-icon>
          </v-ons-toolbar-button>
        </router-link>
      </div>
      <div class="center">
        <router-link :to="`/home`">
          <v-ons-toolbar-button tappable>
            <img
              class="header-image-style"
             src="@/assets/Logo.png"
              alt="SFX Logo"
            />
          </v-ons-toolbar-button>
        </router-link>
      </div>
    </v-ons-toolbar>

    <div class="content-box">
      <img
        class="image-style"
      src="@/assets/Logo.png"
        alt="SFX Logo"
      />
      <p>
        <v-ons-input
          id="assetnum"
          type="text"
          v-model="assetnumber"
          placeholder="Enter Asset Number"
          modifier="underbar"
        ></v-ons-input>
      </p>
      <p>
        <router-link :to="{ name: 'AssetHome', params: { id: assetnumber }}">
        <v-ons-button class="submit" @click="getNum()"
          >Submit</v-ons-button
        >
        </router-link>
      </p>
      <router-link :to="`/assetoverview`">
        <v-ons-button class="no-luck" modifier="quiet"
          >Still No Luck?<br />Try Searching The Asset Database</v-ons-button
        >
      </router-link>
    </div>
    <v-ons-bottom-toolbar class="bottom-bar"></v-ons-bottom-toolbar>
  </v-ons-page>
</template>

<style scoped>
.top-bar {
  border-bottom-style: solid;
  border-bottom-color: #4abca5;
  border-bottom-width: 0px;
}

.header-image-style {
  width: 50px;
  height: 50px;
  object-fit: scale-down;
  padding-bottom: 7px;
}

.content-box {
  text-align: center;
  margin: 60px 25px 25px 25px;
}

.image-style {
  margin-bottom: 25px;
}

.submit {
  background-color: #4abca5;
  color: white;
  margin: 20px 0;
}

.no-luck {
  font-style: italic;
  font-size: 10px;
  color: #837d89;
}

.bottom-bar {
  background-color: #4abca5;
  border-top-style: solid;
  border-top-color: #837d89;
  border-top-width: 5px;
}
</style>

<script>

export default {
  name: "PageManualQR",
  template: "manualqr",
  data() {
    return {
      /*

      <b-card class="mt-3" header="Asset Number Input">
        <pre class="m-0">Asset Number: {{ assetnumber }}</pre>
      </b-card>

     */

      assetnumber: "",
      assetnum: "",
      first: true,
    };
  },
  components: {},

methods:{

getNum(){
fetch("https://www.wolframcloud.com/obj/ccn2/CDN/SFX/vars/last_assetnum?assetnum="+this.assetnumber+'&'+'hash='+this.$root.userhash)
console.log(this.$root.userhash);
console.log(this.assetnumber)
  
}

}


};
</script>
