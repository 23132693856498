<template id="ordertable">
  <div class="table-responsive">
    <b-table
  id="reordertable"
  hover
  striped
  bordered
  selectable
  empty-text
  :items="items"
  :fields="tableColumns"
  class="mt-3 table-backround make-branded"

  >

  <template  #cell(itemAdress) = "data">
  <div style="display: flex;justify-content:space-between;">
    <div> 
      <button style="border: none;background:transparent;color: #4abca5;"  @click="data.toggleDetails">
        {{ data.detailsShowing ? 'v' : '>'}} 
      </button>      
    </div>
  <div>   
       <router-link :to="{ name: 'AssetHome', params: { id: data.item.itemAdress }}">
          <a class="make-branded" @click="getNum(i=data.index)" :href="`#${data.item.itemAdress}`">{{data.item.itemAdress}}</a>
        </router-link>
  </div>
   <div></div>    
  </div>
</template>

<template #row-details="data">
  <div>    
   {{ data.detailsShowing  }}     
  </div>
    <table-asset-info tableColor="#4abca5" :assetNumber="data.item.itemAdress"/>       
</template>

<template #(itemDate)="data">   
   <div>
      {{data.item.itemDate}}
    </div>  
</template>


 </b-table>
</div>
</template>

<style scoped>

.table-backround{
    background-color: rgba(131, 125, 137, 0.15);
}

.make-branded{
  color: #4abca5;
}


</style>

<script>

import TableAssetInfo from './TableAssetInfo.vue'

  export default {
    name: 'OrderHistoryTable',
    template:'ordertable',
    components:{
     TableAssetInfo
    },

    data() {

      return {
    items: [],     
    tableColumns: [
    {key: 'itemAdress', label: "Asset Number", sortable: false },
    {key: 'itemDate', label: "Date Of Reorder", sortable: true},
  ],
    }
  },


async mounted() {
 await this.getAsset();
},

methods: {


async getAsset(){

const data = await fetch("https://www.wolframcloud.com/obj/ccn2/CDN/SFX/forms/getReorders?group="+this.$root.grp)
.then((res) => res.json())
this.items = JSON.parse(data).items
console.log(this.items, this.$root.grp)
  },

  getNum(i){
fetch("https://www.wolframcloud.com/obj/ccn2/CDN/SFX/vars/last_assetnum?assetnum="+this.items[i].item+'&'+'hash='+this.$root.userhash)
console.log(this.$root.userhash);
console.log(this.items[i].item)
}

}


}
</script>
