<template id="login">
  <v-ons-page>
    <v-ons-toolbar class="top-bar"></v-ons-toolbar>
    <div class="content-box">
      <img
        class="image-style"
        src="../assets/Logo.png"
        alt="SFX Logo"
      />
      <form @submit.prevent="submit">

       <p>
          <v-ons-input
            v-model="auth0.group"
            id="group"
            placeholder="Group"
            modifier="underbar"
            type="text"
            autocomplete="group"
            autocorrect="off"
            autocapitalize="off"
            spellcheck="false"
          />
        </p>


        <p>
          <v-ons-input
            v-model="auth0.username"
            id="username"
            placeholder="Username"
            modifier="underbar"
            type="text"
            autocomplete="username"
            autocorrect="off"
            autocapitalize="off"
            spellcheck="false"
          />
        </p>

        <p>
          <v-ons-input
            id="password"
            placeholder="Password"
            modifier="underbar"
            v-model="auth0.password"
            autocomplete="current-password"
            autocorrect="off"
            autocapitalize="off"
            type="password"
          />
        </p>

        <p>
          <v-ons-button ripple class="sign-in" @click="created()"> Sign In</v-ons-button>
        </p>
<transition name="fade">
        <p v-if="showError" id="error" class="branded">Username, Password, or Group is Incorrect</p>
</transition>
<router-link :to="`/authreset`">
        <v-ons-button class="forgot-button" modifier="quiet"
        >Forgot Password or Username?
        </v-ons-button>
 </router-link>
      </form>
      
    </div>
    <v-ons-bottom-toolbar class="bottom-bar"></v-ons-bottom-toolbar>
  </v-ons-page>
</template>

<style scoped>
.top-bar {
  background-color: #4abca5;
  border-bottom-style: solid;
  border-bottom-color: #837d89;
  border-bottom-width: 5px;
}

.content-box {
  text-align: center;
  margin: 60px 25px 25px 25px;
}

.image-style {
  margin-bottom: 25px;
}

.branded{

color: #837d89;
}


.sign-in {
  background-color: #4abca5;
  color: white;
  margin: 20px 0;
}

.forgot-button {
  font-style: italic;
  font-size: 10px;
  color: #837d89;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}

.bottom-bar {
  background-color: #4abca5;
  border-top-style: solid;
  border-top-color: #837d89;
  border-top-width: 5px;
}
</style>

<script>
//import {mapActions} from "vuex";




export default {
  name: "PageLogin",
  template: "login",

  components: {}, 

  data() {

    return {


      auth0: {
        group: null,
        username: null,
        password: null,
      },

      showError: false
    };
  },

mounted(){

this.locatorButtonPressed();

  },


  methods: {

  
  /*...mapActions(["LogIn"]),

    async submit() {
      const User = new FormData();
      User.append("username", this.form.username);
      User.append("password", this.form.password);
      try {
        await this.LogIn(User);
        await this.$router.push("home");
        this.showError = false
      } catch (error) {
        console.log(error);
        this.showError = true
      }
    },

*/

     locatorButtonPressed() {
   navigator.geolocation.getCurrentPosition(
      position => {
         console.log("User Geolocation: ",position.coords.latitude,position.coords.longitude);
      },
      error => {
         console.log(error.message);
      },
   )
},


 created() {
      // Simple GET request using fetch

      fetch(
        "https://www.wolframcloud.com/obj/ccn2/CDN/SFX/auth/validateuser?"+'usr='+this.auth0.username+'&'+'grp='+this.auth0.group+'&'+'pass='+this.auth0.password
      )
        .then((response) => response.json())
        .then((data) => {  if(JSON.parse(data).auth == true)
        {this.$router.replace('/home');}
        else{
          this.showError = true
          console.log("Error")};

         this.$root.userhash = JSON.parse(data).hash
         this.$root.usr = this.auth0.username
         this.$root.grp = this.auth0.group
         this.$root.pass = this.auth0.password
         
         console.log(this.$root.userhash,this.$root.usr,this.$root.grp,this.$root.pass)

         }       
        )

    },
   


  }



};
</script>
