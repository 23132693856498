<template id="toolbar">
  <v-ons-toolbar class="toolbar-color">
    <div class="left">
      <v-ons-toolbar-button @click="action">
        <v-ons-icon class="icon-color" icon="ion-ios-menu"></v-ons-icon>
      </v-ons-toolbar-button>
    </div>
    <div class="center icon-color">{{ title }}</div>
    <div class="right">
      <router-link :to="`/adduser`">
        <v-ons-toolbar-button>
          <v-ons-icon class="icon-color" icon="ion-ios-person-add"></v-ons-icon>
        </v-ons-toolbar-button>
      </router-link>
    </div>
  </v-ons-toolbar>
</template>

<style scoped>
.icon-color {
  color: #4abca5;
}

.toolbar-color {
  background-color: rgba(131, 125, 137, 0.35);
}
</style>

<script>
export default {
  name: "PageNav",
  template: "toolbar",
  props: ["title", "action"],

  data() {
    return {};
  },
};
</script>
