<template id="supportform">
  <div>
    <b-form
    @submit="onSubmit"
      v-if="show"
      class="form-shadow-box content-box"
    >
      <b-form-group
        id="email-input-group"
        label="Email address:"
        label-for="email-input"
        description="We'll never share your email with anyone else."
      >
        <b-form-input
          id="email-input"
          v-model="form.email"
          type="email"
          placeholder="Enter email"
          required
          class="form-shadow"
        ></b-form-input>
      </b-form-group>

     <b-form-group
        id="support-input-group"
        label="Support Type:"
        label-for="support"
      >
        <b-form-input
          placeholder="Select Or Type"
          v-model="form.support0"
          list="support-list"
          id="support"
          required
          class="form-shadow"
        ></b-form-input>
        <b-form-datalist
          id="support-list"
          :options="support"
        ></b-form-datalist>
      </b-form-group>

      <b-form-group
        id="text-input-group"
        label="What Can We Help With?"
        label-for="text-input"
      >
        <b-form-textarea
          id="textarea"
          v-model="form.text"
          required
          placeholder="Describe The Issue In Detail..."
          rows="3"
          max-rows="6"
          class="form-shadow"
        ></b-form-textarea>
      </b-form-group>
      <div class="flex-container">

        
        <b-button
          class="button"
          variant="light"
          type="submit"
          @click="sendMail()"
          >Send</b-button
        >
       
      </div>
    </b-form>
  </div>
</template>

<style scoped>
.content-box {
  margin: 25px;
  text-align: left;
}

.button {
  background-color: #4abca5;
  color: white;
  margin-right: 20px;
}

.flex-container {
  display: flex;
}

.form-shadow {
  box-shadow: rgba(74, 188, 165, 0.35) 0px 5px 15px;
}

.form-shadow-box {
  box-shadow: rgba(74, 188, 165, 0.35) 0px 5px 15px;
  padding: 10px;
  border-radius: 25px;
}
</style>

<script>
export default {
  name: "FormUserSupport",
  template: "supportform",

  data() {
    return {
      form: {
        email: '',
        support0: '',
        text: '',
      },
      show: true,

        support: ['SFX', 'Technical/Software'],
    };
  },


  async mounted() {
 await this.userGet();
},

  methods: {

 async userGet(){
const data = await fetch("https://www.wolframcloud.com/obj/ccn2/CDN/SFX/auth/allusers").then((res) => res.json())
this.items = JSON.parse(data).items
console.log(this.items)

const results = this.items.filter(entry => entry.item.user === this.$root.usr);
this.$root.mail = results[0].item.email
this.form.email = this.$root.mail
console.log(results, results[0].item.email)

},


       onSubmit(event) {

     if(event.preventDefault()){ 
       event.preventDefault()      
     } 
     else{
     alert('Thank you for reaching out! A support team member will contact you shortly.')

     }
       
        console.log("Validation was executed")
      },

    sendMail(){


 if(this.form.support0 == 'SFX'){ this.email = 'christine@sfxray.com'}
 if(this.form.support0 == 'Technical/Software'){ this.email = 'imran@compdatanotes.com'}


fetch("https://www.wolframcloud.com/obj/ccn2/CCN/messaging/email/sendmail?to="+this.email+"&subject=SFX%20App%20Support%20Request&cc="+this.form.email+"&body="+this.form.text+".")
   this.onSubmit()
   },

   
  },
};
</script>
