<template id="blackassettable">
  <div class="table-responsive">
    <b-table
  id="blacktable"
  hover
  striped
  bordered
  selectable
  empty-text
  head-variant="dark"
  :items="items"
  :fields="tableColumns"
  class="mt-3"
  >
<template #table-caption><b-button tappable variant="light" class="save-button"
  @click="$ons.notification.confirm('By confirming, an order request to recycle the selected assets will be sent to SFX.')">Save</b-button>
  </template>

        <template  #cell(itemAdress) = "data">
  <div style="display: flex;justify-content:space-between;">
    <div> 
      <button style="border: none;background:transparent;color: black;"  @click="data.toggleDetails">
        {{ data.detailsShowing ? 'v' : '>'}} 
      </button>      
    </div>
  <div>   
       <router-link :to="{ name: 'AssetHome', params: { id: data.item.itemAdress }}">
          <a class="make-black" @click="getNum(i=data.index)" :href="`#${data.item.itemAdress}`">{{data.item.itemAdress}}</a>
        </router-link>
  </div>
   <div></div>    
  </div>
</template>

<template #row-details="data">
  <div>    
   {{ data.detailsShowing  }}     
  </div>
    <table-asset-info  tableColor="black" :assetNumber="data.item.itemAdress"/>       
</template>

<template #cell(itemDate)="data">   
   <div>
      {{data.item.itemDate}}
    </div>  
</template>

<template v-slot:cell(Recycle)="recycle">
  <b-form-group>
        <b-form-checkbox v-model="recycle.item.Recycle" value="true"
      unchecked-value="false"/>
   </b-form-group>
</template>
 </b-table>
  </div>
</template>

<style scoped>

.table-shadow{
/*box-shadow: rgba(255, 0, 0, 0.35) 0px 5px 15px;*/
border: 2px solid rgba(0, 0, 0, 0.35);
}

.make-black{
  color: black;
}

.save-button{
  background-color: #4abca5;
  color: white;
}

</style>

<script>

import TableAssetInfo from './TableAssetInfo.vue'

  export default {
    name: 'TableBlackAsset',
    template:'blackassettable',
    components:{
     TableAssetInfo
    },
    data() {

      return {
    
    items: [],
    tableColumns: [
    { key: 'itemAdress', label: "Asset Number", sortable: false},
    { key: "itemDate", label: "Date Of Decommission", sortable: true},
    { key: "Recycle", label: "Recycle", sortable: false},
  ],
    }
  },


async mounted() {
 //await this.getDept();
 await this.getBlack();
},

methods: {


/*async getDept(){
const data = await fetch("https://www.wolframcloud.com/obj/ccn2/CDN/SFX/vars/get_last_dept?hash="+this.$root.userhash)
.then(res => res.json())
this.dep = JSON.parse(data).dept
console.log(this.$root.userhash);
console.log(this.dep);
  },
*/


  async getBlack(){
const data = await fetch("https://www.wolframcloud.com/obj/ccn2/CDN/SFX/forms/getDeptDecom?dept="+this.$route.params.id+"&"+"group="+this.$root.grp)
.then((res) => res.json())
this.items = JSON.parse(data).items
console.log(this.items)
},


getNum(i){
fetch("https://www.wolframcloud.com/obj/ccn2/CDN/SFX/vars/last_assetnum?assetnum="+this.items[i].item+'&'+'hash='+this.$root.userhash)
console.log(this.$root.userhash);
console.log(this.items[i].item)  
},


}

}
</script>
