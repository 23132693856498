<template id="assetoverview">
  <v-ons-page>
    <custom-toolbar
      :title="'Asset Overview'"
      :action="toggleMenu"
    ></custom-toolbar>
    <div class="content-box">
      <TableAllAssets />
    </div>
  </v-ons-page>
</template>

<style scoped>
.flex-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
}

.content-box {
  text-align: center;
  margin: 60px 25px 25px 25px;
}

.title {
  font-size: 25px;
  text-align: center;
  color: #4abca5;
}
</style>

<script>
import customToolbar from "./PageNav.vue";
import TableAllAssets from "./TableAllAssets.vue";

export default {
  name: "AssetOverview",
  template: "assetoverview",
  props: ["toggleMenu"],
  components: {
    customToolbar,
    TableAllAssets,
  },
};
</script>
