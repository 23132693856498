<template id="orderhist">
  <v-ons-page>
    <custom-toolbar
      :title="'Order History'"
      :action="toggleMenu"
    ></custom-toolbar>
    <div class="content-box">
      <TableOrderHistory />
    </div>
  </v-ons-page>
</template>

<style scoped>
.content-box {
  text-align: center;
  margin: 60px 25px 25px 25px;
}

.bottom-bar {
  background-color: #4abca5;
  border-top-style: solid;
  border-top-color: #837d89;
  border-top-width: 5px;
}
</style>

<script>
import customToolbar from "./PageNav.vue";
import TableOrderHistory from "./TableOrderHistory.vue";

export default {
  name: "PageOrderHistory",
  template: "orderhist",
  props: ["toggleMenu"],
  components: {
    customToolbar,
    TableOrderHistory,
  },
};
</script>
