<template id="report">
  <v-ons-page>
    <custom-toolbar
      :title="'Asset Report'"
      :action="toggleMenu"
    ></custom-toolbar>

    <div class="content-box">

      <p class="title title-box">General Reporting</p>

      <barPage/>

    </div>
  </v-ons-page>
</template>

<style scoped>


.title {
  font-size: 30px;
  padding: 5px;
  color: #4abca5;
  text-align: center;
}

.title-box {
  margin: 50px 25px 0px 25px;
}

.content-box {
  margin: 60px 25px 0px 25px;
}

.bottom-bar {
  background-color: #4abca5;
  border-top-style: solid;
  border-top-color: #837d89;
  border-top-width: 5px;
}
</style>

<script>
import customToolbar from "./PageNav.vue";
import barPage from './barPage.vue'

export default {
  name: "ReportMaster",
  template: "report",
  props: ["toggleMenu"],

  data() {
    return {};
  },

  components: {
    customToolbar,
    barPage
  },
};
</script>
