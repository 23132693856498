<template>
  <div>
    <v-ons-button class="button" @click="pdfWindowOpen()">Catalog</v-ons-button>

  </div>
</template>

<style scoped>
.modal__content {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
}

.modal-color {
  background-color: rgba(131, 125, 137, 0.8);
}

.box {
  margin: 25px;
  box-shadow: rgba(74, 188, 165, 0.95) 0px 5px 15px;
}

.toolbar {
  padding: 10px;
  background-color: whitesmoke;
  border: transparent;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}

.pages {
  background-color: transparent;
  border-color: transparent;
  width: 5em;
  text-align: center;
  color: black;
}

.align {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
}

.align-botton {

background-color: #4abca5;

}


.button {
  background-color: #4abca5;
  color: white;
  text-align: center;
}
</style>

<script>
import pdf from 'vue-pdf';


export default {
  components: {
    pdf: pdf,
  },
  data() {
  
    return {
      show: true,
      //brochure: null,
      //src: '../assets/static/SFXBrochure.pdf',
      //src: '../public/SFXBrochure.pdf',
      //src: this.brochure,
      loadedRatio: 0,
      page: 1,
      numPages: 0,
      rotate: 0,
      modalVisible: false,
      timeoutID: 0,
      //pdfUrl: this.brochure2

    };
  },



  methods: {

   pdfWindowOpen(){ window.open("https://compdatanotes.com/wp-content/uploads/2022/05/SFX_Brochure.pdf");
   },

/*
    <v-ons-modal
      class="modal-color"
      :visible="modalVisible"
      @click="modalVisible = true"
    >
      <div class="box">
        <div class="toolbar">
          <div class="align">
            <input type="checkbox" v-model="show">
            <b-form-input
              class="pages"
              v-model.number="page"
              type="number"
            ></b-form-input>
            <div class="pages">/{{ numPages }}</div>
          </div>
          <div class="align-botton">
            <v-ons-button class="botton" @click="rotate += 90"
              >&#x27F3;</v-ons-button
            >
            <v-ons-button class="botton" @click="rotate -= 90"
              >&#x27F2;</v-ons-button
            >
            <v-ons-button class="botton" @click="$refs.pdf.print()"
              >Print</v-ons-button
            >
            <v-ons-button class="botton" @click="closeModal">X</v-ons-button>
          </div>
        </div>

        <div style="width: 100%">
          
          <div
            v-if="loadedRatio > 0 && loadedRatio < 1"
            class="botton"
            :style="{ width: loadedRatio * 100 + '%' }"
          >
            {{ Math.floor(loadedRatio * 100) }}%
          </div>
          <pdf
            v-if="show"
            ref="pdf"
            :src= pdfWindowOpen()
            :page="page"
            :rotate="rotate"
            @progress="loadedRatio = $event"
            @error="error"
            @num-pages="numPages = $event"
            @link-clicked="page = $event"
          ></pdf>
        </div>
      </div>
    </v-ons-modal>
*/
    showModal() {
      this.modalVisible = true;
      
    },

    closeModal() {
      this.modalVisible = false;
      clearTimeout(this.timeoutID);
      this.timeoutID = setTimeout(() => (this.modalVisible = false), 100);
    },

    error: function (err) {
      console.log(err);
    },
  }
};
</script>