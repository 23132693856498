<template id="#screeninghistory">
  <v-ons-page>
    <v-ons-toolbar>
      <div class="left">
        <router-link  :to="{ name: 'AssetHome', params: { id: assetnum }}">
          <v-ons-toolbar-button>
            <v-ons-icon
              size="30px"
              style="color: #4abca5"
              icon="fa-arrow-circle-left"
            ></v-ons-icon>
          </v-ons-toolbar-button>
        </router-link>
      </div>
      <div class="center">
        <router-link :to="`/home`">
          <v-ons-toolbar-button tappable>
            <img
              class="header-image-style"
             src="@/assets/Logo.png"
              alt="SFX Logo"
            />
          </v-ons-toolbar-button>
        </router-link>
      </div>
      <div class="right">
        <v-ons-toolbar-button
        id="formprint"
          tappable
         @click="Print()"
        >
          <v-ons-icon
            size="30px"
            style="color: #4abca5"
            icon="fa-file-text"
          ></v-ons-icon>
        </v-ons-toolbar-button>
      </div>
    </v-ons-toolbar>

    <div class="content-box">
      <template id="screeninghistorystableclone">
  <div>
    <b-table
    hover
    striped
    bordered
    selectable
    empty-text
    :items="tableData"
    :fields="tableColumns"
    class="mt-3 table-backround make-branded">

    <template v-slot:cell(ScreeningHistory)>
      
   <div>
      {{date0}}
    </div>
    
</template>

      <template #table-caption>Next Screening Scheduled For {{nextscreen}}</template>
    </b-table>
  </div>
</template>

    </div>
    <v-ons-bottom-toolbar class="bottom-bar"></v-ons-bottom-toolbar>
  </v-ons-page>
</template>


<style scoped>
.header-image-style {
  width: 50px;
  height: 50px;
  object-fit: scale-down;
  padding-bottom: 7px;
}

.content-box {
  text-align: center;
  margin: 60px 25px 25px 25px;
}

.bottom-bar {
  background-color: #4abca5;
  border-top-style: solid;
  border-top-color: #837d89;
  border-top-width: 5px;
}

.table-backround{
    background-color: rgba(131, 125, 137, 0.15);
}

.make-branded{
   color: #4abca5;
}
</style>

<script>

export default {
  name: "PageScreeningHistory",
  template: "screeninghistory",
  components: {
  },
  data() {
    return {

      assetnum: "",
      first: true,
      date0: "",
      nextinspection0: null,
      nextscreen: "",
  

tableData:  [

  {
    ScreeningHistory: ""
    }
  
  ],

        tableColumns: [
          { key: "ScreeningHistory",  label: "Screening History", sortable: true},
         
        ]

    };

  },

  created(){

 this.getNum();

},

async mounted() {
 await this.getData();
},

methods: {

getNum(){
this.assetnum = this.$route.params.id
console.log("hash",this.$root.userhash, " id0 = ",this.$route.params.id," id1:",this.assetnum);
  
},

  async getData() {
    const data = await fetch("https://www.wolframcloud.com/obj/ccn2/CDN/SFX/forms/getform3?assetnum="+this.assetnum).then((res) => res.json())

         this.date0 = JSON.parse(data).date0,
         this.nextinspection0 = JSON.parse(data).nextinspection0

         var parts =this.date0.split('-');
         var mydate = new Date(parts[0], parts[1] - 1, parts[2]);

        function addMonths(date, months) {
    var d = date.getDate();
    date.setMonth(date.getMonth() + +months);
    if (date.getDate() != d) {
      date.setDate(0);
    }
    return date;
}

 if(this.nextinspection0 == "6 Months"){ this.nextinspection0 = 6}
  if(this.nextinspection0 == "12 Months"){ this.nextinspection0 = 12}
    if(this.nextinspection0 == "0 Months"){ this.nextinspection0 = 0}
    
this.nextscreen  =  addMonths(new Date(mydate),this.nextinspection0).toDateString()

         console.log(this.date0, this.nextinspection0, mydate.toDateString(), this.nextscreen)
  
  },


  Print () {
  document.getElementById("formprint")
  window.print();
    }


}



};
</script>
