<template>
  <v-ons-page>
    <v-ons-toolbar>
      <div class="left">
        <router-link :to="`/`">
          <v-ons-toolbar-button>
            <v-ons-icon
              size="30px"
              style="color: #4abca5"
              icon="fa-arrow-circle-left"
            ></v-ons-icon>
          </v-ons-toolbar-button>
        </router-link>
      </div>
      <div class="center">
          <v-ons-toolbar-button tappable>
            <img
              class="header-image-style"
             src="@/assets/Logo.png"
              alt="SFX Logo"
            />
          </v-ons-toolbar-button>
      </div>
    </v-ons-toolbar>

    <div class="content-box">
      <img
        class="image-style"
        src="@/assets/Logo.png"
        alt="SFX Logo"
      />
      <form>
         <p>
          <v-ons-input
            v-model="add.email"
            id="email"
            placeholder="Email"
            modifier="underbar"
            type="text"
          />
        </p>
        <p>
          <v-ons-input
            v-model="add.group"
            id="group"
            placeholder="Group"
            modifier="underbar"
            type="text"
          />
        </p>
      
        <p>
          <v-ons-input
            v-model="add.username"
            id="username"
            placeholder="New Username"
            modifier="underbar"
            type="text"
          />
        </p>
        <p>
          <v-ons-input
            v-model="add.password"
            id="password"
            placeholder="New Password"
            modifier="underbar"
            type="password"
          />
        </p>

        <p>
          <v-ons-button class="sign-in" @click="[userPost(),sendMail()]"
            >Reset</v-ons-button
          >
        </p>
      </form>

    </div>
    <v-ons-bottom-toolbar class="bottom-bar"></v-ons-bottom-toolbar>
  </v-ons-page>
</template>

<style scoped>
.top-bar {
  border-bottom-style: solid;
  border-bottom-color: #4abca5;
  border-bottom-width: 0px;
}

.header-image-style {
  width: 50px;
  height: 50px;
  object-fit: scale-down;
  padding-bottom: 7px;
}

.content-box {
  text-align: center;
  margin: 60px 25px 25px 25px;
}

.image-style {
  margin-bottom: 25px;
}

.list-style {
  color: gray;
  border-style: none;
  background-color: transparent;
  width: 16%;
}

.sign-in {
  background-color: #4abca5;
  color: white;
  margin: 20px 0;
}

.table-backround{
    background-color: rgba(131, 125, 137, 0.15);
}

.make-branded{
   color: #4abca5;
}

.bottom-bar {
  background-color: #4abca5;
  border-top-style: solid;
  border-top-color: #837d89;
  border-top-width: 5px;
}
</style>

<script>
export default {
  name: "UserPassReset",
  template: "userpassreset",
  data() {
    return {


      add: {
        email: null,
        username: null,
        password: null,
        group: null,
      },

    };
  },

  async mounted(){

await this.userHash()
},

  methods:{

async userHash(){

this.$root.userhash = "00000000000000000000";
console.log("User Hash = ",this.$root.userhash)

},

userPost(){

fetch("https://www.wolframcloud.com/obj/ccn2/CDN/SFX/auth/forgotpass?usr="+this.add.username+"&"+"group="+this.add.group+"&"+"newpass="+this.add.password)
console.log(this.add.username,this.add.password,this.add.group)

},

sendMail(){
fetch("https://www.wolframcloud.com/obj/ccn2/CCN/messaging/email/sendmail?to="+this.add.email+"&subject=SFX%20App:%20New%20Username%20Password&cc=imran@compdatanotes.com&body=Login%20Details%20Group:"+this.add.group+", Username:"+this.add.username+", Password:"+this.add.password+"")
console.log(this.add.email,this.add.username,this.add.password,this.add.group)
},

  }


};
</script>
