<template id="addassetscreen">
  <v-ons-page>
    <v-ons-toolbar>
      <div class="left">
        <router-link :to="`/home`">
          <v-ons-toolbar-button>
            <v-ons-icon
              size="30px"
              style="color: #4abca5"
              icon="fa-arrow-circle-left"
            ></v-ons-icon>
          </v-ons-toolbar-button>
        </router-link>
      </div>
      <div class="center">
        <router-link :to="`/home`">
          <v-ons-toolbar-button tappable>
            <img
              class="header-image-style"
              src="@/assets/Logo.png"
              alt="SFX Logo"
            />
          </v-ons-toolbar-button>
        </router-link>
      </div>
      <div class="right">
        <router-link  :to="{ name: 'AssetReport', params: { id: i }}">
          <v-ons-toolbar-button>
            <v-ons-icon
              size="30px"
              style="color: #4abca5"
              icon="fa-file-text"
            ></v-ons-icon>
          </v-ons-toolbar-button>
        </router-link>
      </div>
    </v-ons-toolbar>

    <div class="content-box">
      <div class="flex-d">
        <div>
         <img
          v-if="assettype == null"
            class="image-box"
            src="@/assets/Logo.png"
            alt="SFX PPE"
          />
          <img
          v-else
            class="image-box"
            :src="assettype"
            alt="SFX PPE"
          />
          <figcaption v-if="i" class="caption">Asset ID: {{ i }}</figcaption>
          <QRcodeGenerator />
        </div>
      </div>
      <div class="flex-d">
        <div>
          <ButtonAssetInfo/>
          <figcaption class="caption">Info</figcaption>
        </div>
        <div>
          <ButtonAssetDefect/>
          <figcaption class="caption">Defect</figcaption>
        </div>
      </div>
      <div class="flex-d">
        <div>
          <ButtonScreenAsset/>
          <figcaption class="caption">Screen</figcaption>
        </div>
        <div>
          <ButtonAssetSchedule/>
          <figcaption class="caption">History</figcaption>
        </div>
      </div>
    </div>

    <v-ons-bottom-toolbar class="bottom-bar"></v-ons-bottom-toolbar>
  </v-ons-page>
</template>

<style scoped>
.image-box {
  width: 250px;
  height: 250px;
  margin: 0px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: scale-down;
}

.header-image-style {
  width: 50px;
  height: 50px;
  object-fit: scale-down;
  padding-bottom: 7px;
}

.flex-d {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.content-box {
  text-align: center;
  margin: 60px 25px 60px 25px;
}

.caption {
  font-size: 20px;
  padding: 5px;
  color: #837d89;
}

.bottom-bar {
  background-color: #4abca5;
  border-top-style: solid;
  border-top-color: #837d89;
  border-top-width: 5px;
}
</style>

<script>
import ButtonAssetInfo from "./ButtonAssetInfo.vue";
import ButtonAssetDefect from "./ButtonAssetDefect.vue";
import ButtonAssetSchedule from "./ButtonAssetSchedule.vue";
import ButtonScreenAsset from "./ButtonScreenAsset.vue";
import QRcodeGenerator from "./QRcodeGenerator.vue";


export default {
  name: "AssetHome",
  template: "addassetscreen",
  components: {
    ButtonAssetInfo,
    ButtonAssetDefect,
    ButtonAssetSchedule,
    ButtonScreenAsset,
    QRcodeGenerator,
  },
  data() {
    return {

      assetnum: "",
      i: "",
      assettype: null,

    };


  },


created(){

 this.getNum();

},


 async mounted() {

 await this.getData();

},

methods:{

 getNum(){

fetch("https://www.wolframcloud.com/obj/ccn2/CDN/SFX/vars/last_assetnum?assetnum="+this.$route.params.id+'&'+'hash='+this.$root.userhash)

this.i = this.$route.params.id
console.log("hash",this.$root.userhash, " id0 = ",this.$route.params.id," id1:",this.i);
  
},


  async getData() {
    const data = await fetch("https://www.wolframcloud.com/obj/ccn2/CDN/SFX/forms/getform1?assetnum="+this.i).then((res) => res.json())

    this.assettype = JSON.parse(data).assettype0
    if(this.assettype == "Apron"){ this.assettype = require('@/assets/Apron.png')}
    if(this.assettype == "Thyroid Collar"){ this.assettype = require('@/assets/Thyroidcollar.png')}
    if(this.assettype == "Glasses"){ this.assettype = require('@/assets/Glasses.png')}
    if(this.assettype == "Cap"){ this.assettype = require('@/assets/Cap.png')}
    if(this.assettype == "Drapes"){ this.assettype = require('@/assets/Drapes.png')}
    if(this.assettype == "null"){ this.assettype = require('@/assets/Logo.png')}
    console.log(this.assettype)
    
  }

}

};
</script>
