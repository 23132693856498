//store/modules/auth.js

import axios from 'axios';

const base = ''; // https://sfx.compdatanotes.com/

const state = {
  user: null,
  token: null,
  department: null,
  groups: [],
  departments: [],
};

const getters = {
  isAuthenticated: state => !!state.user,
  StateUser: state => state.user,
  Token: state => state.token,
  Departments: state=> state.departments,
};

const actions = {
  async LogIn({commit}, User) {
    let response = await axios.post(base + 'api/v1/auth/login', User);
    await commit('setUser', User.get('username'));
    this.$token = response.data.data.token;
    await commit('setToken', response.data.data.token);
    await commit('setDepartment', response.data.data.department);
    await commit('setGroups', response.data.data.groups);
  },

  async LogOut({commit}) {
    let user = null
    commit('LogOut', user)
  },

  async GetDepartments({commit}) {
    let config = {headers: {"Authorization": `Bearer ${this.$token}`}};
    let response = await axios.get(
      base + 'api/v1/departments', config)
    commit('setDepts', response.data.data)
  },
};

const mutations = {
  setUser(state, username) {
    state.user = username;
  },
  setToken(state, token) {
    state.token = token;
  },
  setDepartment(state, department) {
    state.department = department;
  },
  setGroups(state, groups) {
    state.groups = groups;
  },
  LogOut(state) {
    state.user = null;
  },
  setDepts(state, depts) {
    state.departments = depts;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
