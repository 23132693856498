<template id="adduser">
  <v-ons-page>
    <v-ons-toolbar>
      <div class="left">
        <router-link :to="`/home`">
          <v-ons-toolbar-button>
            <v-ons-icon
              size="30px"
              style="color: #4abca5"
              icon="fa-arrow-circle-left"
            ></v-ons-icon>
          </v-ons-toolbar-button>
        </router-link>
      </div>
      <div class="center">
        <router-link :to="`/home`">
          <v-ons-toolbar-button tappable>
            <img
              class="header-image-style"
             src="@/assets/Logo.png"
              alt="SFX Logo"
            />
          </v-ons-toolbar-button>
        </router-link>
      </div>
    </v-ons-toolbar>

    <div class="content-box">
      <img
        class="image-style"
        src="@/assets/Logo.png"
        alt="SFX Logo"
      />
      <div>

      <div v-if="this.$root.acc!=='User'">

      <b-form class="center-form" @submit="onSubmit">
       
         <b-form-group
        id="input-group-1"
        label=""
        label-for="email"
      >
          <b-form-input
            v-model="add.email"
            id="email"
            placeholder="Email"
            type="email"
            required
            class="list-style"
          />
      </b-form-group>

        <b-form-group
        id="input-group-2"
        label=""
        label-for="username"
      >
          <b-form-input
            v-model="add.username"
            id="username"
            placeholder="Username"
            type="text"
            required
            class="list-style"
          />
       </b-form-group>

         <b-form-group
        id="input-group-3"
        label=""
        label-for="password"
      >
          <b-form-input
            v-model="add.password"
            id="password"
            placeholder="Password"
            type="password"
            required
            class="list-style"
          />
          </b-form-group>

     <b-form-group
        id="input-group-4"
        label=""
        label-for="group"
      >

          <b-form-input
            v-model="add.group"
            id="group"
            placeholder="Group"
            type="text"
            required
            class="list-style"
          />
  </b-form-group>


<div v-if="this.$root.acc=='SFX Admin'">
             <b-form-select
            id="user-type"
            v-model="add.admins"
            :options="admins"
            required
            class="list-style"
          >
          </b-form-select>
</div>

<div v-else>
           <b-form-select
            id="user-type"
            v-model="addG.Gadmins"
            :options="Gadmins"
            required
            class="list-style"
          >
          </b-form-select>

    </div>  

        <div v-if="this.$root.acc=='SFX Admin'">
        <p>  
          <b-button variant="light" type="submit" class="sign-in" @click="userPostAdmin()"
            >Add User</b-button
          >
        </p>
        </div>

        <div v-else>
        <p>  
          <b-button variant="light" type="submit" class="sign-in" @click="userPost()"
            >Add User</b-button
          >
        </p>
        </div>
      </b-form>
      
<div class="table-responsive table-backround">
      <b-table
  id="newuser"
  hover
  striped
  bordered
  selectable
  empty-text
  :items="items"
  :fields="tableColumns"
  class="mt-3 make-branded"
      >

 <template #cell(email)="data">   
   <div>
  {{data.item.email}}
    </div>  
</template>


<template #cell(user)="data">   
   <div>
     {{data.item.user}}
    </div>  
</template>

<template #cell(pass)="data">   
   <div >
   ****
    </div>  
</template>

<template #cell(group)="data">   
   <div>
    {{data.item.group}}
    </div>  
</template>

<template #cell(access)="data">   
   <div>
    {{data.item.access}}
    </div>  
</template>

     </b-table>
</div>

</div>


  <div v-else>

<div class="table-responsive table-backround">
<template>
    <b-table id="user"
  hover
  striped
  bordered
  selectable
  empty-text
  :items="currentUser"
  class="mt-3 make-branded"></b-table>
</template>
</div>


</div>
</div>
</div>

    <v-ons-bottom-toolbar class="bottom-bar"></v-ons-bottom-toolbar>
  </v-ons-page>
</template>

<style scoped>
.top-bar {
  border-bottom-style: solid;
  border-bottom-color: #4abca5;
  border-bottom-width: 0px;
}

.header-image-style {
  width: 50px;
  height: 50px;
  object-fit: scale-down;
  padding-bottom: 7px;
}

.branded{
color: #4abca5;  
}

.content-box {
  text-align: center;
  margin: 60px 25px 25px 25px;
}

.image-style {
  margin-bottom: 25px;
}

.list-style {
  color: gray;
  border: none;
  background-color: transparent;
  box-shadow: rgba(128, 128, 128, 0.35) 0px 5px 15px;
  border-radius: 10px;
}

.center-form {
  text-align: center;
  box-shadow: rgba(128, 128, 128, 0.35) 0px 5px 15px;
  padding: 10px;
  border-radius: 25px;
  margin: 0px 25px 25px 25px;
}

.sign-in {
  background-color: #4abca5;
  color: white;
  margin: 20px;
}
  

.table-backround{
    background-color: rgba(131, 125, 137, 0.15);
}

.make-branded{
   color: #4abca5;
}

.bottom-bar {
  background-color: #4abca5;
  border-top-style: solid;
  border-top-color: #837d89;
  border-top-width: 5px;
}
</style>

<script>
export default {
  name: "PageAddUser",
  template: "adduser",
  data() {
    return {
   show: true,
   showError: false,

       Gadmins: [
        { text: 'Select One', value: null },
        'Superuser',
        'User'   
      ],

      admins: [
       { text: 'Select One', value: null },
       'SFX Admin',
       'Superuser',
       'Organisation'
      ],

      add: {
        email: '',
        username: '',
        password: '',
        group: '',
        admins: ''
      },

         addG: {
        email: '',
        username: '',
        password: '',
        group: '',
        Gadmins: ''
      },

        item: [],
        items: [],
   
        tableColumns: [
          {key: 'item.email', label: "Email", sortable: false },
          {key: 'item.user', label: "Userame", sortable: false },
          {key: 'pass', label: "Password", sortable: false },
          {key: 'item.group', label: "Group", sortable: false },
          {key: 'item.access', label: "Access", sortable: false }
        ],

       currentUser: [
          {'Group': this.$root.grp, 'Username': this.$root.usr, 'Password': this.$root.pass, 'Access': this.$root.acc}
        ]

    };
  },



  async mounted() {
 await this.userGet();
},

  methods:{

      onSubmit(event) {

     if(event.preventDefault()){ 
       event.preventDefault()     
       
     } 
     else{
 
    if(this.$root.acc=='SFX Admin'){
      this.sendMailAdmin()
       alert(`You have successfully added ${this.add.username} as a new user!`) 
      } else{
       this.sendMail()
       alert(`You have successfully added ${this.addG.username} as a new user!`) 
      }

     }
      },

      onReset(event) {
        event.preventDefault()
        // Reset our form values
        this.add.email = ''
        this.add.username = ''
        this.add.password = ''
        this.add.group = ''
        this.add.admins = ''
        // Trick to reset/clear native browser form validation state
        this.show = false
        this.$nextTick(() => {
          this.show = true
        })
      },

userPostAdmin(){

fetch("https://www.wolframcloud.com/obj/ccn2/CDN/SFX/auth/adduser?usr="+this.add.username+"&"+"grp="+this.add.group+"&"+"pass="+this.add.password+"&"+"access="+this.add.admins+"&"+"email="+this.add.email)
this.onSubmit();
console.log(this.add.email,this.add.username,this.add.password,this.add.group,this.add.admins)

},

userPost(){

fetch("https://www.wolframcloud.com/obj/ccn2/CDN/SFX/auth/adduser?usr="+this.addG.username+"&"+"grp="+this.addG.group+"&"+"pass="+this.addG.password+"&"+"access="+this.addG.Gadmins+"&"+"email="+this.addG.email)
this.onSubmit();
console.log(this.addG.email,this.addG.username,this.addG.password,this.addG.group,this.addG.Gadmins)

},


sendMailAdmin(){
fetch("https://www.wolframcloud.com/obj/ccn2/CCN/messaging/email/sendmail?to="+this.add.email+"&subject=Welcom%20To%20The%20SFX%20App!&cc=imran@compdatanotes.com&body=Login%20Details%20Group:"+this.add.group+", Username:"+this.add.username+", Password:"+this.add.password+"")

},

sendMail(){
fetch("https://www.wolframcloud.com/obj/ccn2/CCN/messaging/email/sendmail?to="+this.addG.email+"&subject=Welcom%20To%20The%20SFX%20App!&cc=imran@compdatanotes.com&body=Login%20Details%20Group:"+this.addG.group+", Username:"+this.addG.username+", Password:"+this.addG.password+"")

},

 async userGet(){

this.isBusy = false
const data = await fetch("https://www.wolframcloud.com/obj/ccn2/CDN/SFX/auth/allusers").then((res) => res.json())
this.items = JSON.parse(data).items
console.log(this.items)

const results = this.items.filter(entry => entry.item.user === this.$root.usr);
this.$root.acc = results[0].item.access
console.log(results, results[0].item.access)

  }

  }


};
</script>
