<template>
  <v-ons-page>
    <v-ons-toolbar>
      <div class="left">
        <router-link :to="`/home`">
          <v-ons-toolbar-button>
            <v-ons-icon
              size="30px"
              style="color: #4abca5"
              icon="fa-arrow-circle-left"
            ></v-ons-icon>
          </v-ons-toolbar-button>
        </router-link>
      </div>
      <div class="center">
        <router-link :to="`/home`">
          <v-ons-toolbar-button tappable>
            <img
              class="header-image-style"
            src="@/assets/Logo.png"
              alt="SFX Logo"
            />
          </v-ons-toolbar-button>
        </router-link>
      </div>
    </v-ons-toolbar>

    <div>
      <p class="title title-box">Asset Breakdown</p>
    </div>


    <div class="content-box">
      <pie-chart
        :chart-id="chartId"
        :width="width"
        :height="height"
        :css-classes="cssClasses"
        :styles="styles"
        :plugins="plugins"
      />

      <div class="icon-flex">
<router-link  :to="{ name: 'TableGreenAssets', params: { id: this.$route.params.id }}">
           <v-ons-icon
              size="30px"
              style="color: rgb(0,128,0, 0.8);margin: 10px"
              icon="fa-chart-pie"
            ></v-ons-icon>
</router-link>
  <router-link  :to="{ name: 'TableAmberAssets', params: { id: this.$route.params.id }}">
             <v-ons-icon
              size="30px"
              style="color: rgb(255,255,0,.8);margin: 10px"
              icon="fa-chart-pie"
            ></v-ons-icon>
</router-link>
<router-link  :to="{ name: 'TableRedAssets', params: { id: this.$route.params.id }}">
             <v-ons-icon
              size="30px"
              style="color: rgb(255,0,0,.8);margin: 10px"
              icon="fa-chart-pie"
            ></v-ons-icon>
</router-link>
<router-link  :to="{ name: 'TableBlackAssets', params: { id: this.$route.params.id }}">
             <v-ons-icon
              size="30px"
              style="color: rgb(0,0,0,.8);margin: 10px"
              icon="fa-chart-pie"
            ></v-ons-icon>
    </router-link>
</div>
</div>

  </v-ons-page>
</template>



<style scoped>
.header-image-style {
  width: 50px;
  height: 50px;
  object-fit: scale-down;
  padding-bottom: 7px;
}

.title {
  font-size: 30px;
  padding: 5px;
  color: #4abca5;
  text-align: center;
}

.title-box {
  margin: 50px 25px 0px 25px;
}

.icon-flex{

display: flex;
flex-direction: row;
justify-content: space-between;

}

.content-box {
  margin: 60px 25px 0px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.bottom-bar {
  background-color: #4abca5;
  border-top-style: solid;
  border-top-color: #837d89;
  border-top-width: 5px;
}
</style>

<script>
import PieChart from "./pieChart.vue";

export default {
  name: "PCAssetOverview",
  components: {
    PieChart,
  },
  props: {
    chartId: {
      type: String,
      default: "pie-chart",
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 400,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

