
<script>
import { Pie } from "vue-chartjs";

export default {
  name: "PieChart",
  extends: Pie,

 
data() {

    return {
    
      chartdata: {

        labels: ["Green Assets", "Amber Assets", "Red Assets"],
        datasets: [
          {
            backgroundColor: [
              "rgb(0,128,0, 0.8)",
              "rgb(255,255,0,.8)",
              "rgb(255,0,0,.8)",
             
            ],

            data: [0, 0, 0],
          },
        ],
      },

      options: {
        responsive: true,
        maintainAspectRatio: false,
      }

}
  },



async mounted() {


 /*const d = await fetch("https://www.wolframcloud.com/obj/ccn2/CDN/SFX/vars/get_last_dept?hash="+this.$root.userhash)
.then(res => res.json())
this.dep = JSON.parse(d).dept
  console.log(this.$root.userhash);
    console.log(this.dep);
*/
  
 const newData = await fetch("https://www.wolframcloud.com/obj/ccn2/CDN/SFX/query/deptrgalist?dept="+this.$route.params.id+"&"+"hash="+this.$root.userhash+"&"+"group="+this.$root.grp)
.then(res => res.json())

const pieCol = [JSON.parse(newData).g, JSON.parse(newData).a, JSON.parse(newData).r]
console.log(JSON.parse(newData).g, JSON.parse(newData).a, JSON.parse(newData).r)

    this.renderChart(
      {
        ...this.chartdata,
        datasets: [
          {
            ...this.chartdata.datasets[0],
            data: pieCol,
          },
        ],
      },
      this.options
    );
  },



methods:{
}


 


};
</script>