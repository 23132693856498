var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('img',{staticClass:"header-image-style",attrs:{"src":require("@/assets/BrandedQr.png"),"alt":"qr-button"},on:{"click":_vm.showModal}}),_c('v-ons-modal',{staticClass:"modal-color",attrs:{"visible":_vm.modalVisible},on:{"click":function($event){_vm.modalVisible = true}}},[_c('figure',{staticClass:"figure content-box border modal-bar"},[_c('qrcode',{ref:"qr",staticClass:"qr-size",attrs:{"value":_vm.value,"tag":"canvas","options":{ 
width: 300

     }}}),_c('p',{staticClass:"text-style"},[_vm._v(_vm._s(_vm.assetnum))]),_c('div',{staticClass:"flex-container"},[_c('v-ons-button',{staticClass:"button-2",attrs:{"variant":"light"},on:{"click":function($event){[
               _vm.saveQR(),
               _vm.closeModal(),
               _vm.$ons.notification.confirm(
                 'A QR Code For This Asset Has Been Sent To Your Email'
               )
             ]}}},[_vm._v("Save")]),_c('v-ons-button',{staticClass:"button-2",attrs:{"variant":"light"},on:{"click":function($event){[
              
               _vm.closeModal()
             ]}}},[_vm._v("Close")])],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }