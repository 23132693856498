<template id="help">
  <v-ons-page>
    <custom-toolbar :title="'Support'" :action="toggleMenu"></custom-toolbar>
    <div class="content-box">
      <FormUserSupport />
    </div>
  </v-ons-page>
</template>

<style scoped>
.content-box {
  margin-top: 60px;
}

.bottom-bar {
  background-color: #4abca5;
  border-top-style: solid;
  border-top-color: #837d89;
  border-top-width: 5px;
}
</style>

<script>
import customToolbar from "./PageNav.vue";
import FormUserSupport from "./FormUserSupport.vue";

export default {
  name: "PageUserSupport",
  template: "help",
  props: ["toggleMenu"],
  components: {
    customToolbar,
    FormUserSupport,
  },
};
</script>
