<template id="notifications">
  <v-ons-page>
    <custom-toolbar
      :title="'Notifications'"
      :action="toggleMenu"
    ></custom-toolbar>
    <div class="content-box">

    <b-table
  hover
  striped
  bordered
  selectable
  empty-text
  head-row-variant="info"
  :items="items"
  :fields="tableColumns"
  class="mt-3 make-branded"
>

<template  #cell(item)="data">
    <div class="make-branded">
    
         {{data.item.item}}

    </div>
</template>

     </b-table>  
    </div>
  </v-ons-page>
</template>

<style scoped>


.content-box {
  text-align: center;
  margin: 60px 25px 25px 25px;
}

.branded {
  background-color: #4abca5;
}

.make-branded{
   color: grey;
}

.flex-d{
background-color: #4abca5;

  }

.bottom-bar {
  background-color: #4abca5;
  border-top-style: solid;
  border-top-color: #837d89;
  border-top-width: 5px;
}
</style>

<script>
import customToolbar from "./PageNav.vue";
export default {
  name: "PageNotifications",
  template: "notifications",
  props: ["toggleMenu"],
  components: { customToolbar },
  data() {
      return {
        item: [],
        items: [],
        tableColumns: [{key: 'item', label: "Notifications", sortable: false }],
       
      }
    },

      async mounted() {
 await this.getNotifications();


},

    methods: {

async getNotifications(){
 const data = await fetch("https://www.wolframcloud.com/obj/ccn2/CDN/SFX/forms/notifications?group="+this.$root.grp)
 .then((res) => res.json())
 this.items = JSON.parse(data).items
 console.log(this.items)
}

    }
};
</script>
