<template>
  <div id="app">
    <!-- the router outlet, where all matched components would ber viewed -->
  
    <router-view></router-view>


  </div>
</template>

<style>


</style>


<script>

export default {
  name: "app",
components:{

  },
  data() {
    return {
     
     
    };
  },

  mounted(){

this.noRefresh()

  },

methods:{


noRefresh(){
  if(this.$root.userhash = 'undefined'){
  this.$router.push('/');
  alert("Your connection was temporarily lost! Due to the sensitivity of your data, we have to log you out.")
  }
  console.log("User has lost their Hash!")
}


}


};
</script>