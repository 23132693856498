<template id="greenassettable">
   <div class="table-responsive">
    <b-table
  id="green"
  hover
  striped
  bordered
  selectable
  empty-text
  head-row-variant="success"
  :items="items"
  :fields="tableColumns"
  class="mt-3 make-green"
>

<template  #cell(item) = "data">
  <div style="display: flex;justify-content:space-between;">
    <div> 
      <button style="border: none;background:transparent;color: green;"  @click="data.toggleDetails">
        {{ data.detailsShowing ? 'v' : '>'}} 
      </button>      
    </div>
  <div>   
       <router-link :to="{ name: 'AssetHome', params: { id: data.item.item }}">
          <a class="make-green" @click="getNum(i=id)" :href="`#${data.item.item}`">{{data.item.item}}</a>
        </router-link>
  </div>
   <div></div>    
  </div>
</template>

<template #row-details="data">
  <div>    
   {{ data.detailsShowing  }}     
  </div>
    <table-asset-info tableColor="green" :assetNumber="data.item.item"/>       
</template>

     </b-table>
   </div>
</template>

<style scoped>





.table-shadow{
/*box-shadow: rgba(255, 0, 0, 0.35) 0px 5px 15px;*/
border: 2px solid rgba(0, 128, 0, 0.35);
}

.make-green{
  color: green;
}

.brand{
  color: #837d89;
}

.content-box {
  text-align: center;
  margin: 60px 25px 60px 25px;
}


</style>

<script>

import TableAssetInfo from './TableAssetInfo.vue'

  export default {
    name: 'TableGreenAsset',
    template:'greenassettable',
     components:{
     TableAssetInfo
    },
    data() {

      return {
        item: [],
        items: [],
        tableColumns: [
        {key: 'item', label: "Asset Number", sortable: false },
        ],
    }
  },

async mounted() {
 //await this.getDept();
 await this.getColor();
},

methods: {


/*
async getDept(){

const d = await fetch("https://www.wolframcloud.com/obj/ccn2/CDN/SFX/vars/get_last_dept?hash="+this.$root.userhash)
.then(res => res.json())
this.dep = JSON.parse(d).dept
  console.log(this.$root.userhash);
    console.log(this.dep);


  },
*/

async getColor(){

const data = await fetch("https://www.wolframcloud.com/obj/ccn2/CDN/SFX/query/deptcolor?dept="+this.$route.params.id+"&color=Green"+"&"+"group="+this.$root.grp)
.then((res) => res.json())
this.items = JSON.parse(data).items
console.log(this.items)
  },

getNum(i){
fetch("https://www.wolframcloud.com/obj/ccn2/CDN/SFX/vars/last_assetnum?assetnum="+this.items[i].item+'&'+'hash='+this.$root.userhash)
console.log(this.$root.userhash);
console.log(this.items[i].item)
  
}

}

}
</script>
