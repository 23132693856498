<template id="allassetstable">
  <div>
    <b-table
    striped
    hover
    bordered
    selectable
    empty-text
    head-row-variant="primary"
    :items="items"
    :fields="Columns"
    class="mt-3t make-blue">

<template #cell(item) = "data">
  <router-link  :to="{ name: 'pieChart', params: { id: data.item.item }}">
     <a @click="fetchLastDept(i=data.index)" :href="`#${data.item.item}`">{{data.item.item}}</a>
     </router-link>
</template>

    </b-table>
  </div>
</template>

<style scoped>

.table-style {
  background-color: rgba(131, 125, 137, 0.3);
}

.make-blue{
  color: blue;
}

</style>

<script>

export default {
  name: 'AllAssetsTable',
  template: 'allassetstable',
  data() {
    return {

        assetnum: "",
        department0: "",
        first: true,
        item: [],
        items: [],


      Columns: [{ key: 'item', label: "All Assets", sortable: false}],

      }
    },




async mounted() {

 await this.getDept();
  await this.fetchLastDept();
},

methods: {


async getDept(){

const data = await fetch("https://www.wolframcloud.com/obj/ccn2/CDN/SFX/forms/alldept?group="+this.$root.grp)
.then((res) => res.json())
this.items = JSON.parse(data).items
console.log(this.items, this.$root.grp)
},



 async fetchLastDept(i) {
    
    //this.$userhash = '7452188639618490057';
    await fetch("https://www.wolframcloud.com/obj/ccn2/CDN/SFX/vars/last_dept?dept="+this.items[i].item+"&"+"hash="+this.$root.userhash)
    console.log(this.$root.userhash);
    console.log(this.items[i].item);
     
  },

}

}
</script>
