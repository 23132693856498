<template>
   <div div class="table-responsive">

  <div style="font-size: 25px;" :style="`color: ${tableColor};`">
         <p>{{assetNumber}}</p>
  </div>
    <b-table
  hover
  striped
  bordered
  selectable
  empty-text
  :items="items"
  :fields="tableColumns"
  :busy.sync="isBusy"
  outlined
  class="mt-3"
  :style="`color: ${tableColor};`"
 
>
 
<template #table-busy>
        <div  :style="`color: ${tableColor};`" class="text-center branded my-2">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </template>
</b-table>

   </div>
</template>

<style scoped>


.branded{
  color: #4abca5;
  /* #4abca5 */
}

.table-shadow{
/*box-shadow: rgba(255, 0, 0, 0.35) 0px 5px 15px;*/
border: 2px solid rgba(0, 128, 0, 0.35);
}

.brand{
  color: #837d89;
  
}

.content-box {
  text-align: center;
  margin: 60px 25px 60px 25px;
}

</style>

<script>

  export default {
    props: {
     assetNumber: String,
     tableColor: String,
    },
    name: 'PropTable',
    template:'prop',
    data() {
      return {
        isBusy: false,
        item: [],
        items: [],
        tableColumns: [
        {key: 'trafficlight0', label: "Trafficlight", sortable: false },
        {key: 'assettype0', label: "Asset", sortable: false },
        {key: 'subtype0', label: "Subtype", sortable: false },
        {key: 'overlap0', label: "Overlap", sortable: false },
        {key: 'manufacturer0', label: "Manufacturer", sortable: false },
        {key: 'date0', label: "Date", sortable: false },
        {key: 'department0', label: "Department", sortable: false },
        {key: 'barrier0', label: "Barrier", sortable: false },
        {key: 'mmLE0', label: "mmLE", sortable: false },
        {key: 'front0', label: "Front", sortable: false },
        {key: 'back0', label: "Back", sortable: false },
        {key: 'pattern0', label: "Pattern", sortable: false },
        ],
    }
  },

async mounted() {
 await this.getData();
},

methods: {
 

  async getData() {

    this.isBusy = true
  try {
    const data = await fetch("https://www.wolframcloud.com/obj/ccn2/CDN/SFX/forms/getform1?assetnum="+`${this.assetNumber}`).then((res) => res.json())
     this.isBusy = false
     console.log(this.items)
     console.log(JSON.parse(data))
    return this.items = [JSON.parse(data)]

  } catch (error) {
    this.isBusy = false
    return []
  }
   
  },

}

}
</script>
