<template id="redassetstable">
  <div class="table-responsive">
    <b-table
  id="redtable"
  hover
  striped
  bordered
  selectable
  empty-text
  head-row-variant="danger"
  :items="items"
  :fields="tableColumns"
  @row-selected="onRowSelected"
  class="mt-3 make-red"
  ref="selectableTable"
  >
    <template #table-caption>
    <div class="flex-d">
  <b-button tappable variant="light" class="save-button" @click="selectAllRows">Select All ✓</b-button>
  <b-button tappable variant="light" class="save-button"
  @click="[onSave(), $ons.notification.confirm('By confirming, an order request for the selected assets will be sent to SFX.')]">Save</b-button>
  </div>
  </template>

   <template #cell(selected)="{ rowSelected }">
        <template  v-if="rowSelected" >
          <span aria-hidden="true">&check;</span>
          <span class="sr-only">Selected</span>
        </template>
        <template v-else>
          <span aria-hidden="true">&nbsp;</span>
          <span class="sr-only">Not selected</span>
        </template>
      </template>

        <template  #cell(item) = "data">
  <div style="display: flex;justify-content:space-between;">
    <div> 
      <button style="border: none;background:transparent;color: red;"  @click="data.toggleDetails">
        {{ data.detailsShowing ? 'v' : '>'}} 
      </button>      
    </div>
  <div>   
       <router-link :to="{ name: 'AssetHome', params: { id: data.item.item }}">
          <a class="make-red" @click="getNum(i=id)" :href="`#${data.item.item}`">{{data.item.item}}</a>
        </router-link>
  </div>
   <div></div>    
  </div>
</template>

<template #row-details="data">
  <div>    
   {{ data.detailsShowing  }}     
  </div>
    <table-asset-info tableColor="red" :assetNumber="data.item.item"/>       
</template>
 

<template  v-slot:cell(reorder)="reord">
   <b-form-group>
       <b-form-checkbox :disabled="disabled == 1" v-model="reord.item.reorder" />
   </b-form-group>
</template>

<template v-slot:cell(decommission)="decom">
  <b-form-group>
        <b-form-checkbox :disabled="disabled == 1" v-model="decom.item.decommission" />
   </b-form-group>
</template>

 </b-table>
  </div>
</template>

<style scoped>

.table-shadow{
/*box-shadow: rgba(255, 0, 0, 0.35) 0px 5px 15px;*/
border: 2px solid rgba(255, 0, 0, 0.35);
}

.make-red{
  color: red;
}

.save-button{
  background-color: #4abca5;
  color: white;
}

.flex-d{
display: flex; 
justify-content: space-between;
}

</style>

<script>

import TableAssetInfo from './TableAssetInfo.vue'

  export default {
    name: 'TableRedAssets',
    template:'redassetstable',
     components:{
     TableAssetInfo
    },
     data(){
       return{
        
      item: [],
      items: [],
      reorder: false,
      decommission: false,
      selected: [],
      disabled: 0,

  tableColumns: [
    { key: 'selected', label: '✓', sortable: false },
    { key: 'item', label: "Asset Number", sortable: false},
    { key: "reorder", label: "Reorder With SFX", sortable: false},
    { key: "decommission", label: "Decommission", sortable: false },
  ],
    }
  },

async mounted() {
// await this.getDept();
  await this.getColor();
},

methods: {
/*
async getDept(){
 const d = await fetch("https://www.wolframcloud.com/obj/ccn2/CDN/SFX/vars/get_last_dept?hash="+this.$root.userhash)
  .then(res => res.json())
   this.dep = JSON.parse(d).dept
  console.log(this.$root.userhash);
console.log(this.dep);
  },this.$route.params.id
  */

async getColor(){

const data = await fetch("https://www.wolframcloud.com/obj/ccn2/CDN/SFX/query/deptcolor?dept="+this.$route.params.id+"&color=Red"+"&"+"group="+this.$root.grp)
  .then((res) => res.json())
 this.items = JSON.parse(data).items
console.log(this.items)
  },

getNum(i){
fetch("https://www.wolframcloud.com/obj/ccn2/CDN/SFX/vars/last_assetnum?assetnum="+this.items[i].item+'&'+'hash='+this.$root.userhash)
console.log(this.$root.userhash);
console.log(this.items[i].item) 
},

onRowSelected(items) {
  this.selected = items
  console.log(this.selected)
},

//https://www.wolframcloud.com/obj/ccn2/CDN/SFX/forms/saveformREDlistPOST

//+this.$root.usr+"&"+"group="+this.$root.grp

onSave(){
  fetch("https://www.wolframcloud.com/obj/ccn2/CDN/SFX/forms/saveformREDlistPOST?", {

    method: "POST",
    body: JSON.stringify(this.selected)+",,,"+this.$root.usr+",,,"+this.$root.grp,
    mode: 'no-cors',
    headers: {"Content-Type": "application/json"}  
 })

 console.log("Post Successful", JSON.stringify(this.selected), this.$root.usr, this.$root.grp)
   },

selectAllRows() {
  this.$refs.selectableTable.selectAllRows()
}

}
}
</script>
