import Vue from 'vue'
import VueRouter from "vue-router";
import store from "../store";
import PageLogin from '../components/PageLogin'
import PageMainHome from '../components/PageMainHome'
import PageAssetHome from '../components/PageAssetHome'
import PageAddUser from '../components/PageAddUser.vue'
import PageManualQR from '../components/PageManualQR.vue'
import AssetReportQR from '../components/AssetReportQR.vue'
import PageAssetInfo from '../components/PageAssetInfo.vue'
import PageAssetOverview from '../components/PageAssetOverview.vue'
import PageReportDefect from '../components/PageReportDefect.vue'
import PageScreeningAsset from '../components/PageScreeningAsset.vue'
import PageScreeningHistory from '../components/PageScreeningHistory.vue'
import AssetsRed from '../components/AssetsRed.vue'
import AssetsAmber from '../components/AssetsAmber.vue'
import AssetsGreen from '../components/AssetsGreen.vue'
import AssetsBlack from '../components/AssetsBlack.vue'
import QRscan from '../components/QRscan.vue'
import CameraCapture from '../components/CameraCapture.vue'
import PCAssetOverview from '../components/PCAssetOverview.vue'
import AssetDatabase from '../components/AssetDatabase.vue'
import PageUserPassReset from '../components/PageUserPassReset.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: PageLogin,
    meta: {guest: true},
  },

  {
    path: '/home',
    component: PageMainHome,
    meta: {requiresAuth: false},
  },
  {
    path: '/assethome/:id',
    name: 'AssetHome',
    component: PageAssetHome,
    meta: {requiresAuth: false},
  },


  {
    path: '/adduser',
    component: PageAddUser,
    meta: {requiresAuth: false},
  },
  {
    path: '/manualQR',
    component: PageManualQR,
    meta: {requiresAuth: false},
  },
  {
    path: '/assetreport/:id',
    name: 'AssetReport',
    component: AssetReportQR,
    meta: {requiresAuth: false},
  },
  {
    path: '/assetinfo/:id',
    name: 'AssetInfo',
    component: PageAssetInfo,
    meta: {requiresAuth: false},
  },
  {
    path: '/assetoverview',
    component: PageAssetOverview,
    meta: {requiresAuth: false},
  },
  {
    path: '/reportdefect/:id',
    name: 'ReportDefect',
    component: PageReportDefect,
    meta: {requiresAuth: false},
  },
  {
    path: '/screening/:id',
    name: 'ScreenAsset',
    component: PageScreeningAsset,
    meta: {requiresAuth: false},
  },
  {
    path: '/assethistory/:id',
    name: 'AssetHistory',
    component: PageScreeningHistory,
    meta: {requiresAuth: false},
  },
  {
    path: '/:id/green',
    name: 'TableGreenAssets',
    component: AssetsGreen,
    meta: {requiresAuth: false},
  },
  {
    path: '/:id/amber',
    name: 'TableAmberAssets',
    component: AssetsAmber,
    meta: {requiresAuth: false},
  },
  {
    path: '/:id/red',
    name: 'TableRedAssets',
    component: AssetsRed,
    meta: {requiresAuth: false},
  },
  {
    path: '/:id/black',
    name: 'TableBlackAssets',
    component: AssetsBlack,
    meta: {requiresAuth: false},
  },
  {
    path: '/qrscan',
    component: QRscan,
    meta: {requiresAuth: false},
  },
  {
    path: '/capture/:id',
    name: 'CameraCapture',
    component: CameraCapture,
    meta: {requiresAuth: false},
  },
  {
    path: '/pie/:id',
    name: 'pieChart',
    component: PCAssetOverview,
    meta: {requiresAuth: false},
  },

  {
    path: '/assetdatabase',
    component: AssetDatabase,
    meta: {requiresAuth: false},
  },

  {
    path: '/authreset',
    component: PageUserPassReset,
    meta: {requiresAuth: false},
  }


];

const router = new VueRouter({
  routes,
  //mode: 'history'
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isAuthenticated) {
      next()
      return
    }
    next('/')
  } else {
    next()
  }
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.guest)) {
    if (store.getters.isAuthenticated) {
      next("/home");
      return;
    }
    next();
  } else {
    next();
  }
});


export default router;

