<template id="assetinfo">
  <v-ons-page>
    <v-ons-toolbar>
      <div class="left">
         <router-link  :to="{ name: 'AssetHome', params: { id: assetnum }}">
          <v-ons-toolbar-button>
            <v-ons-icon
              size="30px"
              style="color: #4abca5"
              icon="fa-arrow-circle-left"
            ></v-ons-icon>
          </v-ons-toolbar-button>
        </router-link>
      </div>
      <div class="center">
        <router-link :to="`/home`">
          <v-ons-toolbar-button tappable>
            <img
              class="header-image-style"
              src="@/assets/Logo.png"
              alt="SFX Logo"
            />
          </v-ons-toolbar-button>
        </router-link>
      </div>
      <div class="right">
        <v-ons-toolbar-button
          tappable
          @click="Print()"
        >
          <v-ons-icon
            size="30px"
            style="color: #4abca5"
            icon="fa-file-text"
          ></v-ons-icon>
        </v-ons-toolbar-button>
      </div>
    </v-ons-toolbar>

    <div id="formprint" class="content-box-div">
    <div>
      <div v-if="form.assettype0 === null">
        <transition name="fade">
        <img class="image-style" src="@/assets/Logo.png" alt="SFX Logo" />
        </transition>
      </div>

      <div v-else-if="form.assettype0 === 'Apron'">
        <transition name="fade"></transition>
        <img class="image-style" src="@/assets/Apron.png" alt="SFX Apron" />
      </div>

      <div v-else-if="form.assettype0 === 'Thyroid Collar'">
        <transition name="fade"></transition>
        <img
          class="image-style"
          src="@/assets/Thyroidcollar.png"
          alt="SFX ThyroidCollar"
        />
      </div>

      <div v-else-if="form.assettype0 === 'Glasses'">
        <transition name="fade"></transition>
        <img class="image-style" src="@/assets/Glasses.png" alt="SFX Glasses" />
      </div>

      <div v-else-if="form.assettype0 === 'Cap'">
        <transition name="fade"></transition>
        <img class="image-style" src="@/assets/Cap.png" alt="SFX Cap" />
      </div>

      <div v-else-if="form.assettype0 === 'Drapes'">
        <transition name="fade"></transition>
        <img class="image-style" src="@/assets/Drapes.png" alt="SFX Drapes" />
      </div>

      <div v-else>
        <transition name="fade">
          <img class="image-style" src="@/assets/Logo.png" alt="SFX Logo" />
        </transition>
      </div>

      <figcaption class="caption">Asset ID: {{ assetnum }}</figcaption>
    </div>
      

      <b-form @submit="onSubmit" v-if="show" class="form-shadow-box content-box">
        <div>
          <input id="id" type="hidden" v-model="form.id0">
          <b-form-group
            id="traffic-input-group"
            label="Traffic Light Colour:"
            label-for="traffic"
          >
            <b-input-group>

           
              <b-form-input
              
                placeholder="Select One"
                id="traffic"
                list="trafficlight-list"
                v-model="form.trafficlight0"
                :disabled="disabled == 1"
                required
                class="form-shadow"
              >
              </b-form-input>

              <b-form-datalist
                id="trafficlight-list"
                :options="trafficlight"
              ></b-form-datalist>
              <b-input-group-append>
                <b-button class="branded" @click="showModal">?</b-button>
                <v-ons-modal
                  class="modal-color"
                  :visible="modalVisible"
                  @click="modalVisible = true"
                >
                  <div class="content-box">
                    <v-ons-card class="ons-card modal__content">
                      <div class="title">Traffic Light Colour</div>
                      <div class="caption">
                        <p style="color: green">Green:</p>
                        Inspect and Screen In 12 Months.
                      </div>
                      <br/>
                      <div class="caption">
                        <p style="color: gold">Amber:</p>
                        Inspect and Screen In 6 Months.
                      </div>
                      <br/>
                      <div class="caption">
                        <p style="color: red">Red:</p>
                        Decomission Immediatley
                      </div>
                      <br/>

                      <b-button @click="closeModal" class="branded" type="submit"
                      >Okay
                      </b-button
                      >
                    </v-ons-card>
                  </div>
                </v-ons-modal>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>

        <b-form-group
          id="assettype-input-group"
          label="Asset Type:"
          label-for="assettype"
        >
          <b-form-input
            placeholder="Select Or Type"
            v-model="form.assettype0"
            list="assettype-list"
            id="assettype"
            required
            :disabled="disabled == 1"
            class="form-shadow"
          ></b-form-input>
          <b-form-datalist
            id="assettype-list"
            :options="assettype"
          ></b-form-datalist>
        </b-form-group>

        <div>
          <div v-if="form.assettype0 === 'Thyroid Collar'">
            <transition name="fade"></transition>
          </div>
          <div v-else-if="form.assettype0 === 'Glasses'">
            <transition name="fade"></transition>
          </div>
          <div v-else-if="form.assettype0 === 'Cap'">
            <transition name="fade"></transition>
          </div>
          <div v-else-if="form.assettype0 === 'Drapes'">
            <transition name="fade"></transition>
          </div>

          <div v-else>
            <transition name="fade">
              <b-form-group
                id="subtype-input-group"
                label="Subtype:"
                label-for="subtype"
              >
                <b-form-input
                  placeholder="Select Or Type"
                  v-model="form.subtype0"
                  list="subtype-list"
                  id="subtype"
                  :disabled="disabled == 1"
                  required
                  class="form-shadow"
                ></b-form-input>
                <b-form-datalist
                  id="subtype-list"
                  :options="subtype"
                ></b-form-datalist>
              </b-form-group>
            </transition>
          </div>
        </div>

        <div>
          <div v-if="form.assettype0 === 'Thyroid Collar'">
            <transition name="fade"></transition>
          </div>
          <div v-else-if="form.assettype0 === 'Glasses'">
            <transition name="fade"></transition>
          </div>
          <div v-else-if="form.assettype0 === 'Cap'">
            <transition name="fade"></transition>
          </div>
          <div v-else-if="form.assettype0 === 'Drapes'">
            <transition name="fade"></transition>
          </div>

          <div v-else>
            <transition name="fade">
              <b-form-group
                id="overlap-input-group"
                label="Front Overlap:"
                label-for="overlap"
              >
                <b-form-input
                  placeholder="Select Or Type"
                  list="overlap-list"
                  v-model="form.overlap0"
                  id="overlap"
                  :disabled="disabled == 1"
                  required
                  class="form-shadow"
                ></b-form-input>
                <b-form-datalist
                  id="overlap-list"
                  :options="overlap"
                ></b-form-datalist>
              </b-form-group>
            </transition>
          </div>
        </div>

        <b-form-group
          id="manufacturer-input-group"
          label="Manufacturer:"
          label-for="manufacturer-input"
        >
          <b-form-input
            placeholder="Select Or Type"
            v-model="form.manufacturer0"
            list="manufacturer-list"
            id="manufacturer-input"
            :disabled="disabled == 1"
            required
            class="form-shadow"
          ></b-form-input>
          <b-form-datalist
            id="manufacturer-list"
            :options="manufacturer"
          ></b-form-datalist>
        </b-form-group>

        <b-form-group
          id="date-input-group"
          label="Date Of Manufacture:"
          label-for="date-input"
        >
          <b-form-input
            id="date-input"
            type="date"
            v-model="form.date0"
            :disabled="disabled == 1"
            required
            class="form-shadow"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="department-input-group"
          label="Department:"
          label-for="department-input"
        >
          <b-form-input
            placeholder="Select Or Type"
            v-model="form.department0"
            list="department-list"
            id="department-input"
            :disabled="disabled == 1"
            required
            class="form-shadow"
          ></b-form-input>
          <b-form-datalist
            id="department-list"
            :options="department"
          ></b-form-datalist>
        </b-form-group>

        <div>
          <div v-if="form.assettype0 === 'Glasses'">
            <transition name="fade"></transition>
          </div>

          <div v-else>
            <transition name="fade">
              <b-form-group
                id="barrier-input-group"
                label="Barrier Material:"
                label-for="barrier-input"
              >
                <b-form-input
                  placeholder="Select Or Type"
                  v-model="form.barrier0"
                  list="barrier-list"
                  id="barrier-input"
                  :disabled="disabled == 1"
                  required
                  class="form-shadow"
                ></b-form-input>
                <b-form-datalist
                  id="barrier-list"
                  :options="barrier"
                ></b-form-datalist>
              </b-form-group>
            </transition>
          </div>
        </div>

        <div>
          <div v-if="form.assettype0 === 'Apron'">
            <transition name="fade"></transition>
          </div>

          <div v-else>
            <transition name="fade">
              <b-form-group
                id="mmLE-input-group"
                label="mmLE:"
                label-for="mmLE-input"
              >
                <b-form-input
                  placeholder="Select Or Type"
                  v-model="form.mmLE0"
                  list="mmLE-list"
                  id="mmLE-input"
                  :disabled="disabled == 1"
                  required
                  class="form-shadow"
                ></b-form-input>
                <b-form-datalist id="mmLE-list" :options="mmLE"></b-form-datalist>
              </b-form-group>
            </transition>
          </div>
        </div>

        <div>
          <div v-if="form.assettype0 === 'Apron'">
            <transition name="fade">
              <b-form-group
                id="front-input-group"
                label="Front mmLE:"
                label-for="front-input"
              >
                <b-form-input
                  placeholder="Select Or Type"
                  v-model="form.front0"
                  list="front-list"
                  id="front-input"
                  :disabled="disabled == 1"
                  required
                  class="form-shadow"
                ></b-form-input>
                <b-form-datalist
                  id="front-list"
                  :options="front"
                ></b-form-datalist>
              </b-form-group>
            </transition>
          </div>

          <div v-else>
            <transition name="fade"></transition>
          </div>
        </div>

        <div>
          <div v-if="form.assettype0 === 'Apron'">
            <transition name="fade">
              <b-form-group
                id="back-input-group"
                label="Back mmLE:"
                label-for="back-input"
              >
                <b-form-input
                  placeholder="Select Or Type"
                  v-model="form.back0"
                  list="back-list"
                  id="back-input"
                  :disabled="disabled == 1"
                  required
                  class="form-shadow"
                ></b-form-input>
                <b-form-datalist id="back-list" :options="back"></b-form-datalist>
              </b-form-group>
            </transition>
          </div>

          <div v-else>
            <transition name="fade"></transition>
          </div>
        </div>

      <b-form-group
        id="pattern-input-group"
        label="Outer Colour/Pattern:"
        label-for="pattern-input"
      >
        <b-form-input
          placeholder="Select Or Type"
          v-model="form.pattern0"
          list="pattern-list"
          id="pattern-input"
          :disabled="disabled == 1"
          required
          class="form-shadow"
        ></b-form-input>
        <b-form-datalist id="pattern-list" :options="pattern"></b-form-datalist>
      </b-form-group>
      <div class="flex-container">
        
        <b-button
          :disabled="disabled == 1"
          @click="[onSave(),(disabled = (disabled + 1) % 2)]"
          class="button"
          variant="light"
          type="submit"
          >Save</b-button
        >
  
        <b-button
          class="button"
          variant="light"
          :disabled="disabled == 0"
          @click="disabled = (disabled + 1) % 2"
          >Edit</b-button
        >
        </div>
      </b-form>
    </div>
    <v-ons-bottom-toolbar class="bottom-bar"></v-ons-bottom-toolbar>
  </v-ons-page>
</template>

<style scoped>

.header-image-style {
  width: 50px;
  height: 50px;
  object-fit: scale-down;
  padding-bottom: 7px;
}


.content-box-div {
  text-align: center;
  margin: 60px 25px 25px 25px;
}

.bottom-bar {
  background-color: #4abca5;
  border-top-style: solid;
  border-top-color: Grey;
  border-top-width: 5px;
}

.title {
  font-size: 30px;
  padding: 5px;
  color: #4abca5;
}

.image-style {
  position: relative;
  width: 250px;
  height: 250px;
  object-fit: scale-down;
}

.caption {
  font-size: 20px;
  padding: 5px;
  color: #837d89;
}

.ons-card {
  box-shadow: rgba(74, 188, 165, 0.9) 0px 5px 15px;
  text-align: center;
  background-color: rgba(245, 245, 245, 0.85);
  border-color: transparent;
}

.modal__content {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
}

.modal-color {
  background-color: rgba(131, 125, 137, 0.8);
}

.branded {
  background-color: #4abca5;
  border: #4abca5;
}

.flex-container {
  display: flex;
  justify-content: space-between;
}

.content-box {
  margin: 25px;
  text-align: left;
}

.button {
  background-color: #4abca5;
  color: white;
  margin-right: 20px;
}

.form-backround {
  background-color: rgba(131, 125, 137, 0.15);
}

.form-shadow {
  box-shadow: rgba(74, 188, 165, 0.35) 0px 5px 15px;
}

.form-shadow-box {
  box-shadow: rgba(74, 188, 165, 0.35) 0px 5px 15px;
  padding: 10px;
  border-radius: 25px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}

</style>

<script>

//import QRcodeGenerator from "./QRcodeGenerator.vue";


export default {
  name: "PageAssetInfo",
  template: "assetinfo",
  components: {

   // QRcodeGenerator,

  },

  data() {
    return {
      disabled: 0,
      assetnum: "",
      trafficlightScreen: '',

      form: {
        id0: 0,
        trafficlight0: '',
       
        assettype0: '',
        subtype0: '',
        overlap0: '',
        manufacturer0: '',
        date0: '',
        department0: '',
        barrier0: '',
        mmLE0: '',
        front0: '',
        back0: '',
        pattern0: '',
      },

      trafficlight: ["Red", "Amber", "Green"],
      assettype: ['Apron', 'Thyroid Collar', 'Glasses', 'Cap', 'Drapes'],
      subtype: ["One-Piece", "Two-Piece"],
      overlap: [
        "Full Front Overlap",
        "Partial Front Overlap",
        "No Front Overlap",
      ],
      manufacturer: [
        "SFXRay",
        "ProtecX",
        "Medray",
        "Amray",
        "APC Cardiovascular",
        "Burlington Medical",
        "Bar Ray",
        "Unsure",
      ],
      department: [
        "Radiology",
        "Cath Lab",
        "Emergency",
        "Diagnostic Imaging Department",
        "Dental",
        "Pharmacy",
      ],
      barrier: ["Low Lead Bilayer", "Lead Free Bilayer", "Composite", "Unsure"],
      mmLE: ["0.25", "0.35", "0.50", "Unsure"],
      front: ["0.175", "0.25", "0.35", "0.50", "Unsure"],
      back: ["0.25", "0.35", "0.50", "Unsure"],
      pattern: ["Blue", "Charcoal", "Navy", "Black"],

      show: true,
      modalVisible: false,
      timeoutID: 0,
    };
  },

 
  
created(){
 this.getNum();
},

async mounted() {
 
 await this.getData();

},


async beforeUpdate(){
  await this.bindTrafficlight();
  console.log("Traffic light color successfully updated")
},


methods: {

   getNum(){
this.assetnum = this.$route.params.id
console.log("hash: ",this.$root.userhash, " id0: ",this.$route.params.id," id1:",this.assetnum);
},

onSubmit(event) {

const paramid = this.assetnum

     if(event.preventDefault()){ 
       event.preventDefault()      
     } 
     else{
       this.$router.push({ name: 'AssetHome', params: { id: paramid  } })
     }
      
        console.log("Validation was executed")
      },


   onSave(){
fetch("https://www.wolframcloud.com/obj/ccn2/CDN/SFX/forms/saveform1user?assetnum="+this.assetnum+"&"+"data="+this.form.trafficlight0+","+this.form.assettype0+","+this.form.subtype0+","+this.form.overlap0+","+this.form.manufacturer0+","+this.form.date0+","+this.form.department0+","+this.form.barrier0+","+this.form.mmLE0+","+this.form.front0+","+this.form.back0+","+this.form.pattern0+"&"+"user="+this.$root.usr+"&"+"group="+this.$root.grp)
  this.onSubmit()
   console.log("asset number onSave: ", this.assetnum)
   },

  async getData() {
    const data = await fetch("https://www.wolframcloud.com/obj/ccn2/CDN/SFX/forms/getform1?assetnum="+this.assetnum).then((res) => res.json())

// this.form.trafficlight0 = JSON.parse(data).trafficlight0,
    this.form.trafficlight0 = JSON.parse(data).trafficlight0,
    
    this.form.assettype0 = JSON.parse(data).assettype0,
    this.form.subtype0 = JSON.parse(data).subtype0,
    this.form.overlap0 = JSON.parse(data).overlap0,
    this.form.manufacturer0 = JSON.parse(data).manufacturer0,
    this.form.date0 = JSON.parse(data).date0,
    this.form.department0 = JSON.parse(data).department0,
    this.form.barrier0 = JSON.parse(data).barrier0,
    this.form.mmLE0 = JSON.parse(data).mmLE0,
    this.form.front0 = JSON.parse(data).front0,
    this.form.back0 = JSON.parse(data).back0,
    this.form.pattern0 = JSON.parse(data).pattern0

    //if(this.trafficlightScreen !== ''){ this.form.trafficlight0 = this.trafficlightScreen }

    console.log("We have detected a change in the traffic light status of",this.assetnum,". The new traffic light status has been changed to, ",this.form.trafficlight0,".")
  },

   async bindTrafficlight() {
    const data = await fetch("https://www.wolframcloud.com/obj/ccn2/CDN/SFX/forms/getform3?assetnum="+this.assetnum).then((res) => res.json())

         this.trafficlightScreen = JSON.parse(data).trafficlight0

          if(this.trafficlightScreen !== ''){ this.form.trafficlight0 = this.trafficlightScreen }
        
        console.log("traffic light colour from screening: ", this.trafficlightScreen)
  },

    Print() {
      document.getElementById("formprint")
      window.print();
    },

    showModal() {
      this.modalVisible = true;
    },
    closeModal() {
      this.modalVisible = false;
      clearTimeout(this.timeoutID);
      this.timeoutID = setTimeout(() => (this.modalVisible = false), 100);
    }

}

};
</script>
