<template id="faqcards">
  <v-ons-page>
    <div class="content-box">
      <v-ons-card class="ons-card">
        <div class="title">What Lead Equivalency Do I Need?</div>
        <div class="caption">
          The first step to deciding what lead equivalency you need is to
          contact the hospital RPA. We usually find that Cath labs need 0.35
          theatres and IR 0.25.
        </div>
      </v-ons-card>
      <br />
      <v-ons-card class="ons-card">
        <div class="title">
          What Is The Difference Between Your Full Frontal Aprons And 3/4
          Aprons?
        </div>
        <div class="caption">
          Our full frontal aprons require the garment to overlap at the front
          from seam to seam. Whereas our partial overlap aprons do not need to
          fully overlap.
        </div>
      </v-ons-card>
      <br />
      <v-ons-card class="ons-card">
        <div class="title">Where Are Your Aprons Manufactured?</div>
        <div class="caption">
          Our aprons are handmade to the highest quality in our head office
          which is located in Belfast, Northern Ireland.
        </div>
      </v-ons-card>
      <br />
      <v-ons-card class="ons-card">
        <div class="title">I Have A Tear In My Apron, What Should I Do?</div>
        <div class="caption">
          If you have the SFX screening service then you do not need to worry
          about this as we will take care of it. If not, the first step is to
          determine if the tear is in the core material or the outer fabric of
          the apron. If it is in the core material then we recommend you replace
          the apron. If the tear is in the outer material only then we offer a
          replacement outer material service.
        </div>
      </v-ons-card>
      <br />
      <v-ons-card class="ons-card">
        <div class="title">What Guarantee Do You Offer?</div>
        <div class="caption">
          We offer a 2 year guarantee which covers any manufacturing faults.
        </div>
      </v-ons-card>
      <br />
      <v-ons-card class="ons-card">
        <div class="title">How Do I Get Measured For A Lead Apron?</div>
        <div class="caption">
          SFX has partnered with 3DLook (contactless measuring software) that
          allows you to be able to send us all your measurements within the
          click of a few buttons. 3DLook takes over 80 measurements so you can
          be confident that we will get the apron right for you.
        </div>
      </v-ons-card>

      <br />

      <v-ons-card class="ons-card">
        <div class="title">
          What Is The Benefit Of Having A Made To Measure Apron Over A Standard
          Size Apron?
        </div>
        <div class="caption">
          When we measure you for an apron we get a better idea of how you wear
          your apron. So we are able to remove some lead where you do not need
          it resulting in a reduction in weight of the apron.
        </div>
      </v-ons-card>

      <br />
      <v-ons-card class="ons-card">
        <div class="title">
          Why Does My Tailored Apron Not Fit Me As I Expected?
        </div>
        <div class="caption">
          This can be due to a number of factors. The core materials in lead
          aprons are metals. As you can imagine metals are rigid materials and
          are very difficult to treat like cotton or any other common clothing
          material. We have various techniques on how to contour the material
          around the body and we do our best to ensure the tailored fit.
        </div>
      </v-ons-card>

      <br />

      <v-ons-card class="ons-card">
        <div class="title">How Do I Clean My Apron?</div>
        <div class="caption">
          Aprons should be cleaned immediately after each use, with care taken
          to remove all traces of blood, barium or other contaminating
          substances. For PVC covered aprons, we recommend the use of a strong
          detergent. Under no circumstances should our aprons be immersed in any
          liquid or subjected to cleaning with solvent based or abrasive
          materials.
        </div>
      </v-ons-card>


       <v-ons-card class="ons-card">
        <div class="title">How To Choose The Correct PPE</div>
        <div class="caption">
           <v-ons-button class="button" @click="selctionChecklist()">Checklist</v-ons-button>
        </div>
      </v-ons-card>

       <v-ons-card class="ons-card">
        <div class="title">How Do I Wear My X-Ray Protection Gear?</div>
        <div class="caption">
           <v-ons-button class="button" @click="wearPPE()">Instructions</v-ons-button>
        </div>
      </v-ons-card>

       <v-ons-card class="ons-card">
        <div class="title">Am I Wearing Full X-Ray Protection Gear?</div>
        <div class="caption">
           <v-ons-button class="button" @click="fullPPE()">Gear Guide</v-ons-button>
        </div>
      </v-ons-card>

       <v-ons-card class="ons-card">
        <div class="title">What If I Am Pregnant?</div>
        <div class="caption">
           <v-ons-button class="button" @click="maternityPoster()">Maternity Guidelines</v-ons-button>
        </div>
      </v-ons-card>

       <v-ons-card class="ons-card">
        <div class="title">Where Can I Learn To Use This App?</div>
        <div class="caption">
           <v-ons-button class="button" @click="userDocs()">User Documentation</v-ons-button>
        </div>
      </v-ons-card>


      <br />
    </div>
  </v-ons-page>
</template>

<style scoped>
.title {
  font-size: 30px;
  padding: 5px;
  color: #4abca5;
}

.caption {
  font-size: 20px;
  padding: 5px;
  color: #837d89;
}

.content-box {
  margin: 60px 25px 0px 25px;
  text-align: center;
}

.ons-card {
  box-shadow: rgba(74, 188, 165, 0.35) 0px 5px 15px;
  text-align: center;
  background-color: rgba(131, 125, 137, 0.1);
  border-color: transparent;
}

.button {
  background-color: #4abca5;
  color: white;
  text-align: center;
}

</style>

<script>
export default {
  name: "CardsFAQ",
  template: "faqcards",
  components: {},
  data() {
    return {};
  },

  methods:{
  
   selctionChecklist(){ window.open("https://compdatanotes.com/wp-content/uploads/2022/05/SFX-Checklist.pdf");
   },
   wearPPE(){ window.open("https://compdatanotes.com/wp-content/uploads/2022/05/SFX-PPE-Instructions.pdf");
   },
   fullPPE(){ window.open("https://compdatanotes.com/wp-content/uploads/2022/05/SFX-Full-PPE.pdf");
   },
   maternityPoster(){ window.open("https://compdatanotes.com/wp-content/uploads/2022/05/SFX-Maternity-Poster.pdf");
   },
   userDocs(){window.open("https://compdatanotes.com/wp-content/uploads/2022/05/SFXRay-User-Documentation.pdf");}

  }
};
</script>t
