<template id="scanqr">
  <router-link :to="`/qrscan`">
    <button class="glossy-button glossy-button--grey">
      <i class="fa fa-camera"></i>
    </button>
  </router-link>
</template>

<script>
export default {
  name: "CameraButton",
  template: "scanqr",
  props: {},
};
</script>

<style scoped>
.glossy-button {
  display: inline-block;
  position: relative;
  background-color: #aaa;
  background-image: linear-gradient(
    /* chrome */ hsla(0, 0%, 100%, 0.6),
    hsla(0, 0%, 100%, 0) 50%,
    hsla(0, 0%, 0%, 0.3) 50%,
    hsla(0, 0%, 100%, 0.2)
  );
  font-size: 80px;
  font-family: sans-serif;
  font-weight: bold;
  color: white;
  padding: 0px 20px;
  text-shadow: 0 0 15px hsla(0, 0%, 100%, 1),
    /* bloom */ 0 2px 4px hsla(0, 0%, 0%, 0.7); /* drop shadow */
  border: none;
  border-radius: 50px;
  margin: 10px;
  box-shadow: inset 0 -5px 20px hsla(0, 0%, 0%, 0.4),
    /* top light */ inset 0 5px 20px hsla(0, 0%, 100%, 0.4),
    /* bottom shadow */ /* multiple light sources yall */ -8px 8px 5px hsla(0, 0%, 0%, 0.15),
    /* drop shadow 1 */ 5px 18px 10px hsla(0, 0%, 0%, 0.2); /* drop shadow 2 */
  cursor: pointer;
  transition: transform 0.1s, box-shadow 0.1s;
}

/* highlight */
.glossy-button:before {
  content: "";
  display: block;
  position: absolute;
  left: 20px;
  right: 20px;
  top: 5px;
  height: 30px;
  border-radius: 15px;
  background: linear-gradient(hsla(0, 0%, 100%, 0.8), hsla(0, 0%, 100%, 0));
}

.glossy-button--grey {
  background-color: black;
}

.glossy-button:hover {
  transform: scale(1.05);
  box-shadow: inset 0 -5px 20px hsla(0, 0%, 0%, 0.4),
    /* top light */ inset 0 5px 20px hsla(0, 0%, 100%, 0.4),
    /* bottom shadow */ /* multiple light sources yall */ -12px 12px 5px hsla(0, 0%, 0%, 0.15),
    /* drop shadow 1 */ 10px 25px 10px hsla(0, 0%, 0%, 0.2); /* drop shadow 2 */
}

.fa-camera {
  color: black;
}
</style>
