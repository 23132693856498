<template id="main">
  <v-ons-page>

    <v-ons-splitter>
      <v-ons-splitter-side
        swipeable
        width="180px"
        collapse=""
        side="left"
        :open.sync="openSide"
      >
        <v-ons-page>

<div v-if="this.$root.acc=='SFX Admin'">

<v-ons-list class="splitter-color">
            <v-ons-list-header class="splitter-color">Menu</v-ons-list-header>
       <v-ons-list-item
              v-for="page in pages"
              :key="page.id"
              tappable
              modifier="chevron"
              @click="
                currentPage = page.id;
                openSide = false;
              "
            >
              <div class="left">
                <v-ons-icon class="icon-c" :icon="page.icon"></v-ons-icon>
              </div>
              <div class="center">{{ page.name }}</div>
            </v-ons-list-item>

            <v-ons-list-item tappable modifier="chevron" @click="logout">
              <div class="left">
                <v-ons-icon class="icon-c" icon="fa-sign-out"></v-ons-icon>
              </div>
              <div class="center">Logout</div>
            </v-ons-list-item>
          </v-ons-list>
</div>

<div v-else>
<v-ons-list class="splitter-color">
            <v-ons-list-header class="splitter-color">Menu</v-ons-list-header>

           <v-ons-list-item
              v-for="hospital in hospitals"
              :key="hospital.id"
              tappable
              modifier="chevron"
              @click="
                currentPage = hospital.id;
                openSide = false;
              "
            >
              <div class="left">
                <v-ons-icon class="icon-c" :icon="hospital.icon"></v-ons-icon>
              </div>
              <div class="center">{{ hospital.name }}</div>
            </v-ons-list-item>

            <v-ons-list-item tappable modifier="chevron" @click="logout">
              <div class="left">
                <v-ons-icon class="icon-c" icon="fa-sign-out"></v-ons-icon>
              </div>
              <div class="center">Logout</div>
            </v-ons-list-item>
          </v-ons-list>
</div>

        </v-ons-page>
      </v-ons-splitter-side>
      <v-ons-splitter-content>
        <div
          :is="currentPage"
          :toggle-menu="() => (openSide = !openSide)"
        ></div>
      </v-ons-splitter-content>
    </v-ons-splitter>

    <v-ons-bottom-toolbar class="bottom-bar"></v-ons-bottom-toolbar>
  </v-ons-page>
</template>

<style scoped>
.icon-c {
  color: #4abca5;
}

.splitter-color {
  background-color: rgba(131, 125, 137, 0.2);
}

.bottom-bar {
  background-color: #4abca5;
  border-top-style: solid;
  border-top-color: #837d89;
  border-top-width: 5px;
  text-align: center;
  padding-top: 10px;
  font-size: x-small;
}
</style>

<script>

import PageHome from "./PageHome.vue";
import PageNotifications from "./PageNotifications.vue";
import PageAssetOverview from "./PageAssetOverview.vue";
import PageAssetReportMaster from "./PageAssetReportMaster.vue";
import PageMeasureMe from "./PageMeasureMe.vue";
import PageGetQuote from "./PageGetQuote.vue";
import PageOrderHistory from "./PageOrderHistory.vue";
import PageRepairHistory from "./PageRepairHistory.vue";
import PageFAQ from "./PageFAQ.vue";
import PageUserSupport from "./PageUserSupport.vue";
//import PageDepartments from "./PageDepartments.vue";

export default {
  name: "PageMainHome",
  template: "main",
  data() {
    return {
      version: 0,
      currentPage: "PageHome",
      pages: [
        {id: "PageHome", name: "Home", icon: "fa-home"},
        {id: "PageNotifications", name: "Notifications", icon: "fa-bell"},
        {
          id: "PageAssetOverview",
          name: "Asset Overview",
          icon: "fa-chart-pie",
        },
        {
          id: "PageAssetReportMaster",
          name: "Asset Report",
          icon: "fa-file-text",
        },
        {id: "PageMeasureMe", name: "Measure Me", icon: "fa-ruler"},
        {id: "PageGetQuote", name: "Get Quote", icon: "fa-shopping-cart"},
        {
          id: "PageOrderHistory",
          name: "Order History",
          icon: "fa-shipping-fast",
        },
        {id: "PageRepairHistory", name: "Repair History", icon: "tools"},
        {id: "PageFAQ", name: "FAQ", icon: "fa-comments"},
        {id: "PageUserSupport", name: "Support", icon: "fa-question-circle"},
      ],

/*

   <router-link :to="`/departments`">
              <v-ons-list-item tappable modifier="chevron">
                <div class="left">
                  <v-ons-icon class="icon-c" icon="fa-hospital-o"></v-ons-icon>
                </div>
                <div class="center">Departments</div>
              </v-ons-list-item>
            </router-link>

          <v-ons-list class="splitter-color">
            <v-ons-list-header class="splitter-color">Menu</v-ons-list-header>
       <v-ons-list-item
              v-for="page in pages"
              :key="page.id"
              tappable
              modifier="chevron"
              @click="
                currentPage = page.id;
                openSide = false;
              "
            >
              <div class="left">
                <v-ons-icon class="icon-c" :icon="page.icon"></v-ons-icon>
              </div>
              <div class="center">{{ page.name }}</div>
            </v-ons-list-item>

            <v-ons-list-item tappable modifier="chevron" @click="logout">
              <div class="left">
                <v-ons-icon class="icon-c" icon="fa-sign-out"></v-ons-icon>
              </div>
              <div class="center">Logout</div>
            </v-ons-list-item>
          </v-ons-list>

*/

         hospitals: [
        {id: "PageHome", name: "Home", icon: "fa-home"},
        {id: "PageNotifications", name: "Notifications", icon: "fa-bell"},
        {
          id: "PageAssetOverview",
          name: "Asset Overview",
          icon: "fa-chart-pie",
        },
        {id: "PageMeasureMe", name: "Measure Me", icon: "fa-ruler"},
        {id: "PageGetQuote", name: "Get Quote", icon: "fa-shopping-cart"},
        {
          id: "PageOrderHistory",
          name: "Order History",
          icon: "fa-shipping-fast",
        },
        {id: "PageRepairHistory", name: "Repair History", icon: "tools"},
        {id: "PageFAQ", name: "FAQ", icon: "fa-comments"},
        {id: "PageUserSupport", name: "Support", icon: "fa-question-circle"},
      ],


      openSide: false,
    };
  },



  methods: {
    async logout() {
      await this.$store.dispatch('LogOut')
      await this.$router.push('/')

    },

        async userGet(){

const data = await fetch("https://www.wolframcloud.com/obj/ccn2/CDN/SFX/auth/allusers").then((res) => res.json())
this.items = JSON.parse(data).items
console.log(this.items)

const results = this.items.filter(entry => entry.item.pass === this.$root.pass);
this.$root.acc = results[0].item.access
console.log("User Access Level:",this.$root.acc)

  }


  },

  async mounted() {

    await this.userGet();
    //v{{ version }}
    this.$http.get('/api/v1/version')
      .then(resp => {
        if (resp.data.code === 0) {
          this.version = resp.data.data.version;
        } else {
          console.log(resp.data);
          this.version = 'unknown';
        }
      })
      .catch(error => {
        console.log(error)
      });
      
  },


  components: {
    PageHome,
    PageNotifications,
    PageAssetOverview,
    PageAssetReportMaster,
    PageMeasureMe,
    PageGetQuote,
    PageOrderHistory,
    PageRepairHistory,
    PageFAQ,
    PageUserSupport,
    //PageDepartments,
  },
};
</script>




