<template id="qrgen">
  <div>
    
  <img @click="showModal" class="header-image-style" src="@/assets/BrandedQr.png" alt="qr-button">
    
    <v-ons-modal
      class="modal-color"
      :visible="modalVisible"
      @click="modalVisible = true"
    >
      <figure class="figure content-box border modal-bar">

         <qrcode
          :value="value"
          tag="canvas"
          ref="qr"
          class="qr-size"
           
          :options="{ 
 width: 300

      }"
        ></qrcode>
         <p class="text-style">{{assetnum}}</p>
        <div class="flex-container">
          <v-ons-button
            @click="
              [
                saveQR(),
                closeModal(),
                $ons.notification.confirm(
                  'A QR Code For This Asset Has Been Sent To Your Email'
                )
              ]
            "
            class="button-2"
            variant="light"
            >Save</v-ons-button
          >

             <v-ons-button
            @click="
              [
               
                closeModal()
              ]
            "
            class="button-2"
            variant="light"
            >Close</v-ons-button
          >

        </div>
      </figure>
    </v-ons-modal>
  </div>
</template>

<style scoped>


.header-image-style {
  width: 100px;
  height: 100px;
  object-fit: scale-down;
  padding-bottom: 7px;
}



.flex-container {
  display: flex;
  justify-content: space-evenly;
}

.modal-bar {
  background-color: rgba(255, 255, 255, 0.35);
  box-shadow: rgba(74, 188, 165, 0.5) 0px 5px 15px;
}

.content-box {
  margin: 25px;
}

.qr-size {
  height: auto;
  width: 100%;
  /* even more control with max-width */
  max-width: 720px;
}

.button {
  background-color: #4abca5;
  color: white;
  margin-right: 20px;
}

.button-2 {
  background-color: #4abca5;
  color: white;
  margin: 0px 20px 20px 20px;
  border: none;
}

.text-style{
  text-align: center;
  color: #4abca5; 
  margin-top: 10px ;
  font-size: 25px;
}

.modal-color {
  background-color: rgba(131, 125, 137, 0.8);
}

.box {
  text-align: center;
  margin: 60px 25px 25px 25px;
  background-color: rgba(131, 125, 137, 0.15);
  box-shadow: rgba(74, 188, 165, 0.95) 0px 5px 15px;
}

.make-branded {
  color: #4abca5;
}
</style>

<script>
import QrcodeVue from "qrcode.vue";

export default {
  name: "QRcodeGenerator",
  template: "qrgen",
  components: {
    QrcodeVue
  },
  data() {
    return {
      /*
<b-card class="mt-3" header="Form Data Result">
    <pre class="m-0">{{ tableData }}</pre>
   </b-card>
        */


      assetnum: "",
      first: true,
      modalVisible: false,
      timeoutID: 0,
      value: "",
      size: 300,
    };
  },
  

//async mounted() {
// await this.fetchAssetNum();
//},

created(){

 this.getNum();

                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         
},

methods: {

   getNum(){

this.assetnum = this.$route.params.id
this.value = this.assetnum
console.log("hash: ",this.$root.userhash, " id0: ",this.$route.params.id," id1:",this.assetnum);
  
},

   saveQR() {
      let img = this.$refs.qr.$el.toDataURL();
      var link = document.createElement("a");
      link.download = `${this.assetnum}.png`; 
      link.href = img;
      document.body.appendChild(link);
      link.click();


      fetch("https://www.wolframcloud.com/obj/ccn2/CCN/messaging/email/sendmailPNG",  {
    method: "POST",
    body: "qr@sfxray.com"+",,,"+`${this.$root.grp} QR Code:${this.assetnum}`+",,,"+"imran@compdatanotes.com"+",,,"+img,
    mode: 'cors'
  })

  console.log("mail sent!",img," group:",this.$grp)
    },

  

    showModal() {
      this.modalVisible = true;
    },

    closeModal() {
      this.modalVisible = false;
      clearTimeout(this.timeoutID);
      this.timeoutID = setTimeout(() => (this.modalVisible = false), 100);
    },
    error: function (err) {
      console.log(err);
    },
  },
};
</script>
