<template id="measureme">
  <v-ons-page>
    <custom-toolbar :title="'Measure Me'" :action="toggleMenu"></custom-toolbar>
    <div class="content-box">
      <v-ons-card class="ons-card">
        <div class="title">3DLOOK</div>
        <div class="caption">
          The global leader in AI-first mobile body scanning solutions that help
          you virtually measure your entire team.
        </div>
        <br />
          <div class="saia-widget-container button"></div>
      <script type="application/javascript" defer  id="saia-mtm-integration" async src="https://mtm-widget.3dlook.me/integration.js" data-public-key="NjEyNA:1nM6Sk:6T702pcpi1PGN2t_F0Tke25loiU"></script>
      </v-ons-card>
    </div>
  </v-ons-page>
</template>

<style scoped>
.content-box {
  margin: 60px 25px 0px 25px;
  text-align: center;
}

.button {
  background-color:  rgba(74, 188, 165) !important;
  border-color:  rgba(74, 188, 165) !important;
  background: black !important;
  
}


.ons-card {
  box-shadow: rgba(74, 188, 165, 0.35) 0px 5px 15px;
  text-align: center;
  background-color: rgba(131, 125, 137, 0.1);
  border-color: transparent;
}

.title {
  font-size: 30px;
  padding: 5px;
  color: #4abca5;
}

.caption {
  font-size: 20px;
  padding: 5px;
  color: #837d89;
}

.bottom-bar {
  background-color: #4abca5;
  border-top-style: solid;
  border-top-color: #837d89;
  border-top-width: 5px;
}
</style>

<script>
import customToolbar from "./PageNav.vue";


export default {
  name: "PageMeasureMe",
  template: "measureme",
  props: ["toggleMenu"],
  components: { customToolbar },



  methods: {

  },
};
</script>

