<template id="#black">
  <v-ons-page>
    <v-ons-toolbar>
      <div class="left">

          <v-ons-toolbar-button @click="backTick()">
            <v-ons-icon
              size="30px"
              style="color: #4abca5"
              icon="fa-arrow-circle-left"
            ></v-ons-icon>
          </v-ons-toolbar-button>

      </div>
      <div class="center">
        <router-link :to="`/home`">
          <v-ons-toolbar-button tappable>
            <img
              class="header-image-style"
              src="@/assets/Logo.png"
              alt="SFX Logo"
            />
          </v-ons-toolbar-button>
        </router-link>
      </div>
      <div class="right">
        <v-ons-toolbar-button
          tappable
          @click="Print()"
        >
          <v-ons-icon
            size="30px"
            style="color: #4abca5"
            icon="fa-file-text"
          ></v-ons-icon>
        </v-ons-toolbar-button>
      </div>
    </v-ons-toolbar>

    <div class="content-box">
      <TableBlackAsset />
    </div>
    <v-ons-bottom-toolbar class="bottom-bar"></v-ons-bottom-toolbar>
  </v-ons-page>
</template>


<style scoped>
.header-image-style {
  width: 50px;
  height: 50px;
  object-fit: scale-down;
  padding-bottom: 7px;
}

.content-box {
  text-align: center;
  margin: 60px 25px 25px 25px;
}

.bottom-bar {
  background-color: #4abca5;
  border-top-style: solid;
  border-top-color: #837d89;
  border-top-width: 5px;
}
</style>

<script>
import TableBlackAsset from "./TableBlackAsset.vue";

export default {
  name: "blackAssets",
  template: "black",
  components: {
    TableBlackAsset,
  },
  data() {
    return {};
  },

  methods:{
       backTick(){
      this.$router.back()
    },
       Print() {
      document.getElementById("formprint")
      window.print();
    },
  }
};
</script>
