<template id="home">
  <v-ons-page>
    <custom-toolbar :title="'Home'" :action="toggleMenu"></custom-toolbar>
    <div class="content-box">
      <section class="align-gen">
        <div class="align-buttons">
          <ButtonAddAsset />
          <p>Add Asset</p>
        </div>

        <div class="align-buttons">
          <ButtonCamera />
          <p>Scan QR Code</p>
        </div>
        <div class="align-gen">
          <router-link :to="`/manualqr`">
            <v-ons-button modifier="quiet" class="scan-button"
              >QR Code Not Scanning?
            </v-ons-button>
          </router-link>
        </div>
      </section>
    </div>
  </v-ons-page>
</template>

<style scoped>
.content-box {
  margin-top: 60px;
}

.align-gen {
  text-align: center;
}

.align-buttons {
  text-align: center;
  margin: 30px 0;
}

.scan-button {
  margin: 6px 0;
  font-size: 16px;
  font-style: italic;
  color: #837d89;
}
</style>


<script>
import ButtonAddAsset from "./ButtonAddAsset.vue";
import ButtonCamera from "./ButtonCamera.vue";
import customToolbar from "./PageNav.vue";

export default {
  name: "PageHome",
  props: ["toggleMenu"],
  template: "home",
  data() {
    return {};
  },
  components: {
    customToolbar,
    ButtonAddAsset,
    ButtonCamera,
  },
};
</script>
