<template id="defectreport">
  <v-ons-page>
  <v-ons-toolbar>
      <div class="left">
        <router-link  :to="{ name: 'AssetHome', params: { id: assetnum }}">
          <v-ons-toolbar-button>
            <v-ons-icon
              size="30px"
              style="color: #4abca5"
              icon="fa-arrow-circle-left"
            ></v-ons-icon>
          </v-ons-toolbar-button>
        </router-link>
      </div>
      <div class="center">
        <router-link :to="`/home`">
          <v-ons-toolbar-button tappable>
            <img
              class="header-image-style"
              src="@/assets/Logo.png"
              alt="SFX Logo"
            />
          </v-ons-toolbar-button>
        </router-link>
      </div>
      <div class="right">
        <v-ons-toolbar-button
        id="formprint"
          tappable
        @click="Print()"
        >
          <v-ons-icon
            size="30px"
            style="color: #4abca5"
            icon="fa-file-text"
          ></v-ons-icon>
        </v-ons-toolbar-button>
      </div>
    </v-ons-toolbar>

    <div>
      <div v-if="showcarousel === true">
        <transition name="fade"></transition>
      </div>
      <div v-else>
        <transition name="fade">
          <div class="image-flex-box">
            <div class="image-box">
              <div class="camera-button">
              
              
              
              
              
               <router-link :to="{ name: 'CameraCapture', params: { id: assetnum }}">
    <button class="glossy-button glossy-button--grey">
      <i class="fa fa-camera"></i>
    </button>
  </router-link>
              
              
              
              
              
              
              
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>

    <div>
      <div v-if="showcarousel === false">
        <transition name="fade"></transition>
      </div>

      <div v-else>
        <div class="image-flex-box">
          <div class="image-box">
            <transition name="fade">
              <b-carousel
                id="carousel-1"
                v-model="slide"
                :interval="0"
                controls
                indicators
                background="transparent"
                @sliding-start="onSlideStart"
                @sliding-end="onSlideEnd"
              >
                <b-carousel-slide>
                  <template #img>
                    <img
                      class="d-block img-fluid w-100 image-style"
                      ref="defect"
                      :src="defectimage"
                      alt="image slot"
                    />
                  </template>
                </b-carousel-slide>
                <!-- Slides with img slot -->
                <!-- Note the classes .d-block and .img-fluid to prevent browser default image alignment -->
                <b-carousel-slide>
                  <template #img>
                    <img
                      class="d-block img-fluid w-100 image-style"
                      :src="defectimage"
                      alt="image slot"
                    />
                  </template>
                </b-carousel-slide>
              </b-carousel>
            </transition>
          </div>
        </div>
      </div>
    </div>

    <div class="content-box">
      <figcaption>
        <v-ons-button
          class="retake-button"
          modifier="quiet"
          v-model="showcarousel"
          @click="showcarousel = true"
          >Toggle Slideshow</v-ons-button
        >

        <v-ons-button
          class="retake-button"
          modifier="quiet"
          v-model="showcarousel"
          @click="showcarousel = false"
          >Toggle Camera</v-ons-button
        >
      </figcaption>

       <figcaption class="caption">Asset ID: {{ assetnum }}</figcaption>
      <figcaption>
        <router-link :to="{ name: 'CameraCapture', params: { id: assetnum }}">
        <v-ons-button class="retake-button" modifier="quiet"
          >Retake Photo</v-ons-button
        >
        </router-link>
      </figcaption>
    </div>

  <div>
    <div>

     


      <div v-if="showdefect === true">
        <transition name="fade" >
          <v-ons-card class="ons-card content-box">
            <div class="title">Defect Report</div>

            <div class="card-caption">
              <div
                v-if="
                  form.assettype === 'Apron' &&
                  form.defectlocation0 === 'Over Critical Organ' &&
                  form.teardimensions0 === 'Tear > 20mm'
                "
              >
                <p>
                  Using the information provided, the BIR Guidelines recommend
                  this asset be allocated a
                  <span class="red-a"> RED </span>
                  status and decommissioned immediately.
                </p>
              </div>

              <div
                v-if="
                  form.assettype === 'Apron' &&
                  form.defectlocation0 === 'Over Critical Organ' &&
                  form.teardimensions0 === 'Tear < 20mm'
                "
              >
                <p>
                  Using the information provided, the BIR Guidelines recommend
                  this asset be allocated an
                  <span class="amber-a"> Amber </span> status and screened again
                  in 6 months time.
                </p>
              </div>

              <div
                v-if="
                  form.assettype === 'Apron' &&
                  form.defectlocation0 === 'Not Over Critical Organ' &&
                  form.teardimensions0 === 'Tear > 50mm'
                "
              >
                <p>
                  Using the information provided, the BIR Guidelines recommend
                  this asset be allocated a
                  <span class="red-a"> RED </span>
                  status and decommissioned immediately.
                </p>
              </div>

              <div
                v-if="
                  form.assettype === 'Apron' &&
                  form.defectlocation0 === 'Not Over Critical Organ' &&
                  form.teardimensions0 === 'Tear < 50mm'
                "
              >
                <p>
                  Using the information provided, the BIR Guidelines recommend
                  this asset be allocated an
                  <span class="amber-a"> Amber </span> status and screened again
                  in 6 months time.
                </p>
              </div>

              <div
                v-if="
                  form.assettype === 'Thyroid Collar' &&
                  form.teardimensions0 === 'Tear > 20mm'
                "
              >
                <p>
                  Using the information provided, the BIR Guidelines recommend
                  this asset be allocated a
                  <span class="red-a"> RED </span>
                  status and decommissioned immediately.
                </p>
              </div>

              <div
                v-if="
                  form.assettype === 'Thyroid Collar' &&
                  form.teardimensions0 === 'Tear < 20mm'
                "
              >
                <p>
                  Using the information provided, the BIR Guidelines recommend
                  this asset be allocated an
                  <span class="amber-a"> Amber </span> status and screened again
                  in 6 months time.
                </p>
              </div>





                            <div
                    v-if="
                  form.assettype === 'Drapes'
                 
                "
              >
                <p>
                  Using the information provided, we recommend
                  this asset be allocated a
                  <span class="red-a"> RED </span>
                  status and decommissioned immediately.
                </p>
              </div>

                 <div
                    v-if="
                  form.assettype === 'Glasses'
                 
                "
              >
                <p>
                  Using the information provided, we recommend
                  this asset be allocated a
                  <span class="red-a"> RED </span>
                  status and decommissioned immediately.
                </p>
              </div>

                 <div
                    v-if="
                  form.assettype === 'Cap'
                 
                "
              >
                <p>
                  Using the information provided, we recommend
                  this asset be allocated a
                  <span class="red-a"> RED </span>
                  status and decommissioned immediately.
                </p>
              </div>



            </div>
          <TableBIR/>
          </v-ons-card>
        </transition>
      </div>


    <div v-else>

        <transition name="fade">
        </transition>
      </div>

    </div>

    <b-form  @submit="onSubmit" v-if="show" class="form-shadow-box content-box-2">
      <b-form-group
        id="assettype-input-group"
        label="Asset Type:"
        label-for="assettype"
      >
        <b-form-input
          placeholder="Select Or Type"
          v-model="form.assettype"
          list="assettype-list"
          id="assettype"
          :disabled="disabled"
          required
          class="form-shadow"
        ></b-form-input>
      
      </b-form-group>

      <div>
        <div v-if="form.assettype === 'Thyroid Collar'">
          <transition name="fade"></transition>
        </div>

        <div v-else>
          <transition name="fade">
            <b-form-group
              id="defect-input-group"
              label="Defect Location:"
              label-for="defectlocation"
            >
              <b-form-input
                placeholder="Select Or Type"
                v-model="form.defectlocation0"
                list="defectlocation-list"
                id="defectlocation"
                :disabled="disabled"
                required
                class="form-shadow"
              ></b-form-input>
               
              <b-form-datalist
                id="defectlocation-list"
                :options="defectlocation"
              ></b-form-datalist>
            </b-form-group>
          </transition>
        </div>
      </div>

      <b-form-group
        id="tear-input-group"
        label="Tear Dimensions:"
        label-for="teardimensions"
         
      >
        <b-form-input
          placeholder="Select Or Type"
          v-model="form.teardimensions0"
          list="teardimensions-list"
          id="teardimensions"
          :disabled="disabled"
          required
          class="form-shadow"
        ></b-form-input>

        <div v-if="form.assettype === 'Thyroid Collar'">
          <transition name="fade"></transition>
          <b-form-datalist
            id="teardimensions-list"
            :options="teardimensionsTC"
          ></b-form-datalist>
        </div>

        <div
          v-if="
            form.assettype === 'Apron' &&
            form.defectlocation0 === 'Over Critical Organ'
          "
        >
          <transition name="fade"></transition>
          <b-form-datalist
            id="teardimensions-list"
            :options="teardimensionsACO"
          ></b-form-datalist>
        </div>

        <div
          v-if="
            form.assettype === 'Apron' &&
            form.defectlocation0 === 'Not Over Critical Organ'
          "
        >
          <transition name="fade"></transition>
          <b-form-datalist
            id="teardimensions-list"
            :options="teardimensionsANCO"
          ></b-form-datalist>
        </div>
      </b-form-group>

      <div class="flex-container">
        
        <b-button
          class="form-button"
          variant="light"
          v-model="showdefect"
          
          type="submit"
          @click="onSave()"
          >Save</b-button
        >
        <b-button
          class="form-button"
          variant="light"
          v-model="showdefect"
          @click="[disabled = false, showdefect = false]"
          >Edit</b-button
        >
      </div>
    </b-form>
  </div>
  </v-ons-page>
</template>

<style scoped>

.header-image-style {
  width: 50px;
  height: 50px;
  object-fit: scale-down;
  padding-bottom: 7px;
}

.camera-button {
  width: 150px;
  height: 150px;
  text-align: center;
}

.image-box {
  width: 250px;
  height: 250px;
  margin: 0px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: scale-down;
}

.caption {
  font-size: 20px;
  padding: 5px;
  color: #837d89;
}

.card-caption {
  font-size: 20px;
  padding: 5px;
  color: #837d89;
}

.title {
  font-size: 30px;
  padding: 5px;
  color: #4abca5;
}

.bir-link {
  font-style: italic;
  font-size: 15px;
  color: Gray;
}

.image-flex-box {
  margin: 60px 25px 0px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-style {
  position: relative;
  width: 250px;
  height: 250px;
  object-fit: scale-down;
}

.content-box {
  margin: 0px 25px 0px 25px;
  text-align: center;
}

.ons-card {
  box-shadow: rgba(74, 188, 165, 0.35) 0px 5px 15px;
  text-align: center;
  background-color: rgba(131, 125, 137, 0.1);
  border-color: transparent;
}

.retake-button {
  font-style: italic;
  font-size: 10px;
  color: #837d89;
}

.bottom-bar {
  background-color: #4abca5;
  border-top-style: solid;
  border-top-color: #837d89;
  border-top-width: 5px;
}

.content-box-2 {
  margin: 25px;
  text-align: left;
}

.form-button {
  background-color: #4abca5;
  color: white;
  margin-right: 20px;
}

.flex-container {
  display: flex;
  justify-content: space-between;
}

.form-shadow {
  box-shadow: rgba(74, 188, 165, 0.35) 0px 5px 15px;
}

.form-shadow-box {
  box-shadow: rgba(74, 188, 165, 0.35) 0px 5px 15px;
  padding: 10px;
  border-radius: 25px;
}

.caption {
  font-size: 20px;
  padding: 5px;
}

.card-caption {
  font-size: 20px;
  font-style: italic;
  padding: 5px;
  color: #837d89;
}

.title {
  font-size: 30px;
  padding: 5px;
  color: #4abca5;
}

.bir-link {
  font-style: italic;
  font-size: 15px;
  color: #837d89;
}

.button-link {
  background-color: transparent;
}

.red-a {
  color: red;
}
.amber-a {
  color: gold;
}

.ons-card {
  box-shadow: rgba(74, 188, 165, 0.35) 0px 5px 15px;
  text-align: center;
  background-color: rgba(131, 125, 137, 0.1);
  border-color: transparent;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to  /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.glossy-button {
  display: inline-block;
  position: relative;
  background-color: #aaa;
  background-image: linear-gradient(
    /* chrome */ hsla(0, 0%, 100%, 0.6),
    hsla(0, 0%, 100%, 0) 50%,
    hsla(0, 0%, 0%, 0.3) 50%,
    hsla(0, 0%, 100%, 0.2)
  );
  font-size: 80px;
  font-family: sans-serif;
  font-weight: bold;
  color: white;
  padding: 0px 20px;
  text-shadow: 0 0 15px hsla(0, 0%, 100%, 1),
    /* bloom */ 0 2px 4px hsla(0, 0%, 0%, 0.7); /* drop shadow */
  border: none;
  border-radius: 50px;
  margin: 10px;
  box-shadow: inset 0 -5px 20px hsla(0, 0%, 0%, 0.4),
    /* top light */ inset 0 5px 20px hsla(0, 0%, 100%, 0.4),
    /* bottom shadow */ /* multiple light sources yall */ -8px 8px 5px hsla(0, 0%, 0%, 0.15),
    /* drop shadow 1 */ 5px 18px 10px hsla(0, 0%, 0%, 0.2); /* drop shadow 2 */
  cursor: pointer;
  transition: transform 0.1s, box-shadow 0.1s;
}

/* highlight */
.glossy-button:before {
  content: "";
  display: block;
  position: absolute;
  left: 20px;
  right: 20px;
  top: 5px;
  height: 30px;
  border-radius: 15px;
  background: linear-gradient(hsla(0, 0%, 100%, 0.8), hsla(0, 0%, 100%, 0));
}

.glossy-button--grey {
  background-color: black;
}

.glossy-button:hover {
  transform: scale(1.05);
  box-shadow: inset 0 -5px 20px hsla(0, 0%, 0%, 0.4),
    /* top light */ inset 0 5px 20px hsla(0, 0%, 100%, 0.4),
    /* bottom shadow */ /* multiple light sources yall */ -12px 12px 5px hsla(0, 0%, 0%, 0.15),
    /* drop shadow 1 */ 10px 25px 10px hsla(0, 0%, 0%, 0.2); /* drop shadow 2 */
}

.fa-camera {
  color: black;
}

</style>

<script>

import TableBIR from './TableBIR.vue'

export default {
  name: "FormReportDefect",
  template: "defectform",
  components:{
    TableBIR,
  
  },

  data() {
    return {

      assetnum: "",
      defectimage: "",
      slide: 0,
      sliding: null,
      showcarousel: false,
      disabled: false,

      form: {
        assettype: '',
        defectlocation0: '',
        teardimensions0: '',
      },

      //assettype: ['Apron', 'Thyroid Collar'],
      defectlocation: ['Over Critical Organ', 'Not Over Critical Organ'],
      teardimensionsACO: ['Tear > 20mm', 'Tear < 20mm'],
      teardimensionsANCO: ['Tear > 50mm', 'Tear < 50mm'],
      teardimensionsTC: ['Tear > 20mm', 'Tear < 20mm'],
      show: true,
      showdefect: false,
    };
  },




created(){
 this.getNum();
},

async mounted(){
  await this.getAsset();
   await this.userGet();
},

methods: {


       onSubmit(event) {

     if(event.preventDefault()){ 
       event.preventDefault()  
     
     } 
     else{
     alert('This defect has successfully been reported and sent to your email.')
     this.sendMail()
     this.showdefect = true;
     this.disabled = true;
     }
      },

   getNum(){
this.assetnum = this.$route.params.id
 console.log("hash: ",this.$root.userhash, " id0: ",this.$route.params.id," id1: ",this.assetnum);
   this.defectimage = `https://www.wolframcloud.com/obj/ccn2/CDN/SFX/image/largebase64out?assetnum=${this.assetnum}`;
     console.log("image url = ", this.defectimage)
},

   async getAsset() {
    const data = await fetch("https://www.wolframcloud.com/obj/ccn2/CDN/SFX/forms/getform1?assetnum="+this.assetnum).then((res) => res.json())
    this.form.assettype = JSON.parse(data).assettype0
    console.log("asset type on getAsset: ", this.form.assettype)
    
  },

   async userGet(){
const data = await fetch("https://www.wolframcloud.com/obj/ccn2/CDN/SFX/auth/allusers").then((res) => res.json())
this.items = JSON.parse(data).items
console.log(this.items)

const results = this.items.filter(entry => entry.item.user === this.$root.usr);
this.$root.mail = results[0].item.email
this.useremail = this.$root.mail
console.log(results, results[0].item.email)

},

  sendMail(){
fetch("https://www.wolframcloud.com/obj/ccn2/CCN/messaging/email/sendmail?to="+this.useremail+"&subject=Defect%20Report:%20"+this.assetnum+"&cc=imran@compdatanotes.com&body="+this.$root.usr+" from "+this.$root.grp+" has reported a "+this.form.teardimensions0+" on an asset with the ID "+this.assetnum+".")
console.log("Defect report email was sent successfully!")
},

        onSave(){
fetch("https://www.wolframcloud.com/obj/ccn2/CDN/SFX/forms/saveform2user?assetnum="+this.assetnum+"&"+"data="+this.form.assettype+","+this.form.defectlocation0+","+this.form.teardimensions0+"&"+"user="+this.$root.usr+"&"+"group="+this.$root.grp)
    
      this.onSubmit()
    
    },

   

     Print () {
  document.getElementById("formprint")
  window.print();
    },

    onSlideStart(slide) {
      this.sliding = true;
    },
    onSlideEnd(slide) {
      this.sliding = false;
    }
}

};
</script>
