<template id="assetreportA">
  <v-ons-page>
    <v-ons-toolbar>
      <div class="left">
        <router-link :to="{ name: 'AssetHome', params: { id: i } }">
          <v-ons-toolbar-button>
            <v-ons-icon
              size="30px"
              style="color: #4abca5"
              icon="fa-arrow-circle-left"
            ></v-ons-icon>
          </v-ons-toolbar-button>
        </router-link>
      </div>
      <div class="center">
        <router-link :to="`/home`">
          <v-ons-toolbar-button tappable>
            <img
              class="header-image-style"
              src="@/assets/Logo.png"
              alt="SFX Logo"
            />
          </v-ons-toolbar-button>
        </router-link>
      </div>
    </v-ons-toolbar>

    <div class="content-box">
      <div class="flex-d">
        <div>
          <img
            v-if="assettype == null"
            class="image-box"
            src="@/assets/Logo.png"
            alt="SFX PPE"
          />
          <img v-else class="image-box" :src="assettype" alt="SFX PPE" />
          <figcaption class="caption">Asset ID: {{ i }}</figcaption>
        </div>
      </div>

      <div class="flex-d">
        <div>
          <ButtonAssetInfo />
          <figcaption class="caption">Info</figcaption>

          <figcaption>
            <b-form-checkbox
              class="check-box"
              id="info-check"
              v-model="report.info"
              name="info-report"
              value="report"
              unchecked-value="dont report"
            >
            </b-form-checkbox>
            <figcaption class="caption2">Report</figcaption>
          </figcaption>

          <v-ons-button class="button modal-button" @click="showModal"
            >Date</v-ons-button
          >
          <v-ons-modal
            class="modal-color"
            :visible="modalVisible"
            @click="modalVisible = true"
          >
            <div class="content-box">
              <b-form class="form-shadow-box">
                <b-form-group
                  class="title"
                  id="info-startdate-input"
                  label="Choose Starting Date"
                  label-for="info-startdate"
                >
                  <b-form-datepicker
                    id="info-startdate"
                    placeholder="Choose Date"
                    v-model="report.Infostartdate"
                    :max="report.Infomaxstart"
                    today-button
                    reset-button
                    close-button
                    locale="en"
                    class="mb-2 form-shadow placeholder"
                  >
                  </b-form-datepicker>
                </b-form-group>

                <b-form-group
                  class="title"
                  id="info-enddate-input"
                  label="Choose Ending Date"
                  label-for="info-enddate"
                >
                  <b-form-datepicker
                    id="info-enddate"
                    placeholder="Choose Date"
                    v-model="report.Infoenddate"
                    :min="report.Infostartdate"
                    :max="report.Infomaxend"
                    today-button
                    reset-button
                    close-button
                    class="mb-2 form-shadow placeholder"
                    locale="en"
                  >
                  </b-form-datepicker>
                </b-form-group>

                <b-button @click="closeModal" class="button" variant="light"
                  >Save</b-button
                >
              </b-form>
            </div>
          </v-ons-modal>
        </div>

        <div>
          <ButtonAssetDefect />
          <figcaption class="caption">Defect</figcaption>
          <figcaption>
            <b-form-checkbox
              class="check-box"
              id="defect-check"
              v-model="report.defect"
              name="defect-report"
              value="report"
              unchecked-value="dont report"
            >
            </b-form-checkbox>
            <figcaption class="caption2">Report</figcaption>
          </figcaption>

          <v-ons-button class="button modal-button" @click="showModal"
            >Date</v-ons-button
          >
          <v-ons-modal
            class="modal-color"
            :visible="modalVisible"
            @click="modalVisible = true"
          >
            <div class="content-box">
              <b-form class="form-shadow-box">
                <b-form-group
                  class="title"
                  id="info-startdate-input"
                  label="Choose Starting Date"
                  label-for="info-startdate"
                >
                  <b-form-datepicker
                    id="info-startdate"
                    placeholder="Choose Date"
                    v-model="report.Infostartdate"
                    :max="report.Infomaxstart"
                    today-button
                    reset-button
                    close-button
                    locale="en"
                    class="mb-2 form-shadow placeholder"
                  >
                  </b-form-datepicker>
                </b-form-group>

                <b-form-group
                  class="title"
                  id="info-enddate-input"
                  label="Choose Ending Date"
                  label-for="info-enddate"
                >
                  <b-form-datepicker
                    id="info-enddate"
                    placeholder="Choose Date"
                    v-model="report.Infoenddate"
                    :min="report.Infostartdate"
                    :max="report.Infomaxend"
                    today-button
                    reset-button
                    close-button
                    class="mb-2 form-shadow placeholder"
                    locale="en"
                  >
                  </b-form-datepicker>
                </b-form-group>

                <b-button @click="closeModal" class="button" variant="light"
                  >Save</b-button
                >
              </b-form>
            </div>
          </v-ons-modal>
        </div>
      </div>

      <div class="flex-d">
        <div>
          <ButtonScreenAsset />
          <figcaption class="caption">Screen</figcaption>

          <figcaption>
            <b-form-checkbox
              class="check-box"
              id="screen-check"
              v-model="report.screening"
              name="screen-report"
              value="report"
              unchecked-value="dont report"
            >
            </b-form-checkbox>
            <figcaption class="caption2">Report</figcaption>
          </figcaption>

          <v-ons-button class="button modal-button" @click="showModal"
            >Date</v-ons-button
          >
          <v-ons-modal
            class="modal-color"
            :visible="modalVisible"
            @click="modalVisible = true"
          >
            <div class="content-box">
              <b-form class="form-shadow-box">
                <b-form-group
                  class="title"
                  id="info-startdate-input"
                  label="Choose Starting Date"
                  label-for="info-startdate"
                >
                  <b-form-datepicker
                    id="info-startdate"
                    placeholder="Choose Date"
                    v-model="report.Infostartdate"
                    :max="report.Infomaxstart"
                    today-button
                    reset-button
                    close-button
                    locale="en"
                    class="mb-2 form-shadow placeholder"
                  >
                  </b-form-datepicker>
                </b-form-group>

                <b-form-group
                  class="title"
                  id="info-enddate-input"
                  label="Choose Ending Date"
                  label-for="info-enddate"
                >
                  <b-form-datepicker
                    id="info-enddate"
                    placeholder="Choose Date"
                    v-model="report.Infoenddate"
                    :min="report.Infostartdate"
                    :max="report.Infomaxend"
                    today-button
                    reset-button
                    close-button
                    class="mb-2 form-shadow placeholder"
                    locale="en"
                  >
                  </b-form-datepicker>
                </b-form-group>

                <b-button @click="closeModal" class="button" variant="light"
                  >Save</b-button
                >
              </b-form>
            </div>
          </v-ons-modal>
        </div>

        <div>
          <ButtonAssetSchedule />
          <figcaption class="caption">History</figcaption>

          <figcaption>
            <b-form-checkbox
              class="check-box"
              id="schedule-check"
              v-model="report.schedule"
              name="schedule-report"
              value="report"
              unchecked-value="dont report"
            >
            </b-form-checkbox>
            <figcaption class="caption2">Report</figcaption>
          </figcaption>

          <v-ons-button class="button modal-button" @click="showModal"
            >Date</v-ons-button
          >
          <v-ons-modal
            class="modal-color"
            :visible="modalVisible"
            @click="modalVisible = true"
          >
            <div class="content-box">
              <b-form class="form-shadow-box">
                <b-form-group
                  class="title"
                  id="info-startdate-input"
                  label="Choose Starting Date"
                  label-for="info-startdate"
                >
                  <b-form-datepicker
                    id="info-startdate"
                    placeholder="Choose Date"
                    v-model="report.Infostartdate"
                    :max="report.Infomaxstart"
                    today-button
                    reset-button
                    close-button
                    locale="en"
                    class="mb-2 form-shadow placeholder"
                  >
                  </b-form-datepicker>
                </b-form-group>

                <b-form-group
                  class="title"
                  id="info-enddate-input"
                  label="Choose Ending Date"
                  label-for="info-enddate"
                >
                  <b-form-datepicker
                    id="info-enddate"
                    placeholder="Choose Date"
                    v-model="report.Infoenddate"
                    :min="report.Infostartdate"
                    :max="report.Infomaxend"
                    today-button
                    reset-button
                    close-button
                    class="mb-2 form-shadow placeholder"
                    locale="en"
                  >
                  </b-form-datepicker>
                </b-form-group>

                <b-button @click="closeModal" class="button" variant="light"
                  >Save</b-button
                >
              </b-form>
            </div>
          </v-ons-modal>
        </div>
      </div>

      <v-ons-button
        class="submit-report"
        @click="$ons.notification.confirm('Export and Forward Report?')"
        >Export Report</v-ons-button
      >
    </div>
    <v-ons-bottom-toolbar class="bottom-bar"></v-ons-bottom-toolbar>
  </v-ons-page>
</template>

<style scoped>
.submit-report {
  background-color: #4abca5;
  color: white;
  margin: 20px 0;
}

.bottom-bar {
  background-color: #4abca5;
  border-top-style: solid;
  border-top-color: #837d89;
  border-top-width: 5px;
}

.image-box {
  width: 250px;
  height: 250px;
  margin: 0px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: scale-down;
}

.header-image-style {
  width: 50px;
  height: 50px;
  object-fit: scale-down;
  padding-bottom: 7px;
}

.flex-d {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.content-box {
  text-align: center;
  margin: 60px 25px 60px 25px;
}

.caption {
  font-size: 20px;
  padding: 5px;
  color: #837d89;
}
.caption2 {
  font-size: 15px;
  padding: 5px;
  color: #837d89;
}

.bottom-bar {
  background-color: #4abca5;
  border-top-style: solid;
  border-top-color: #837d89;
  border-top-width: 5px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.button {
  background-color: #4abca5;
  color: white;
}

.modal-color {
  background-color: rgba(131, 125, 137, 0.8);
}
</style>

<script>
import ButtonAssetInfo from "./ButtonAssetInfo.vue";
import ButtonAssetDefect from "./ButtonAssetDefect.vue";
import ButtonAssetSchedule from "./ButtonAssetSchedule.vue";
import ButtonScreenAsset from "./ButtonScreenAsset.vue";

export default {
  name: "AssetReportQR",
  template: "assetreportA",

  components: {
    ButtonAssetInfo,
    ButtonAssetDefect,
    ButtonAssetSchedule,
    ButtonScreenAsset,
  },

  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const InfomaxDateStart = new Date(today - 1);
    const InfomaxDateEnd = new Date(today);
    return {
      assetnum: "",
      first: true,
      assettype: null,
      i: "",

      report: {
        info: "dont report",
        defect: "dont report",
        screening: "dont report",
        schedule: "dont report",
        Infostartdate: "",
        Infoenddate: "",
        Infomaxstart: InfomaxDateStart,
        Infomaxend: InfomaxDateEnd,
      },

      modalVisible: false,
      timeoutID: 0,
    };
  },

  async mounted() {
    await this.getNum();
    await this.getData();
  },

  methods: {
    async getNum() {
      fetch(
        "https://www.wolframcloud.com/obj/ccn2/CDN/SFX/vars/last_assetnum?assetnum=" +
          this.$route.params.id +
          "&" +
          "hash=" +
          this.$root.userhash
      );

      this.i = this.$route.params.id;
      console.log(this.$root.userhash);

      console.log(this.i);
    },

    async getData() {
      const data = await fetch(
        "https://www.wolframcloud.com/obj/ccn2/CDN/SFX/forms/getform1?assetnum=" +
          this.i
      ).then((res) => res.json());

      this.assettype = JSON.parse(data).assettype0;
      if (this.assettype == "Apron") {
        this.assettype = require("@/assets/Apron.png");
      }
      if (this.assettype == "Thyroid Collar") {
        this.assettype = require("@/assets/Thyroidcollar.png");
      }
      if (this.assettype == "Glasses") {
        this.assettype = require("@/assets/Glasses.png");
      }
      if (this.assettype == "Cap") {
        this.assettype = require("@/assets/Cap.png");
      }
      if (this.assettype == "Drapes") {
        this.assettype = require("@/assets/Drapes.png");
      }
      if (this.assettype == "null") {
        this.assettype = require("@/assets/Logo.png");
      }
      console.log(this.assettype);
    },

    showModal() {
      this.modalVisible = true;
    },
    closeModal() {
      this.modalVisible = false;
      clearTimeout(this.timeoutID);
      this.timeoutID = setTimeout(() => (this.modalVisible = false), 100);
    },
  },
};
</script>
