<script>
import { Bar } from 'vue-chartjs'
export default {
  name: 'BarChart',
  extends: Bar,
  data: () => ({
    chartdata: {
      labels: [
        'Aprons',
        'Thyroid Collar',
        'Glasses',
        'Cap',
        'Drapes'
      ],
      datasets: [
        {
          label: 'PPE Distribution',
           backgroundColor: 
              "rgb(74, 188, 165, .6)",
              
            
          data: [40, 20, 12, 39, 23]
        }
      ]
    },
    options: {
      responsive: true,
      maintainAspectRatio: false
    }
  }),
  mounted() {
    this.renderChart(this.chartdata, this.options)
  }
}
</script>